import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Slider from '../../ui/slider/Slider';
import useLabels from '../../hooks/useLabels';
import InputLabel from '../../ui/input-label/InputLabel';
import style from './TemperatureSlider.module.scss';
import { withUIState } from '../../contexts/ui-state/withUIState';

type Props = {
    value: number;
    hideLabel?: boolean;
    isDisabled?: boolean;
    isLoading?: boolean;
    onChange?: (value: number) => void;
};

const min = 0;
const max = 10;

const TemperatureSlider: React.FC<Props> = ({ hideLabel, value, isDisabled, isLoading, onChange }) => {
    const labels = useLabels();
    const l = useMemo(() => {
        return {
            label: labels.temperature,
            minLabel: labels.temperatureMin,
            midLabel: labels.temperatureMid,
            maxLabel: labels.temperatureMax,
        };
    }, [labels.temperature, labels.temperatureMax, labels.temperatureMid, labels.temperatureMin]);

    const [localValue, setLocalValue] = useState(1);
    useEffect(() => {
        setLocalValue(prev => prev === value ? prev : value);
    }, [value, onChange]);

    const onChangeHandler = useCallback((value: number) => {
        setLocalValue(value);
        onChange?.(value);
    }, [onChange]);

    return (
        <div className={style['temperature-slider']}>
            {!hideLabel && <InputLabel label={l.label} isDisabled={isDisabled || isLoading} />}
            <Slider min={min} max={max} value={localValue} onChange={onChangeHandler} isDisabled={isDisabled || isLoading} step={1} />

            <div className={style.captions}>
                <span>{l.minLabel}</span>
                <span>{l.midLabel}</span>
                <span>{l.maxLabel}</span>
            </div>
        </div>
    );
};

export default withUIState(TemperatureSlider);