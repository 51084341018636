import usePersistentInputState from '../../views/chat-view/usePersistentInputState';
import usePersistentAutoSubmitState from '../../views/chat-view/usePersistentLoadingForState';
import { PropsWithChildren, createContext, useContext } from 'react';
import { GetChatResponse, SessionResponse, ChatMetadata, ChatMessageStream, ChatMessageTracesResponse, DocumentInfoModel, DocumentUpdateRequest, DocumentsResponse, DeleteDocumentsResponse } from '../../models/ChatTypes';
import { ChatMessageFeedback, ProductivityGainResponse, UpdateChatMetadataResponse, UserPreferences } from '../../models/types';
import { IChatService } from '../../services/IChatService';
import { ComponentsData, TableRowsResponse } from '../../models/SidePanelTypes';
import { NotImplementedEventHandler } from '../../services/EventHandler';

type ExtendedIChatService = IChatService & {
    persistent: ReturnType<typeof usePersistentInputState> & ReturnType<typeof usePersistentAutoSubmitState>;
};

const defaultChatService: IChatService = {
    getChat: function (sessionId: string): Promise<GetChatResponse> {
        throw new Error('Function not implemented.');
    },
    getAllChats: function (): Promise<SessionResponse[]> {
        throw new Error('Function not implemented.');
    },
    createChat: function (initialMessage: string, llm: string, temperature: number, persona?: string, version?: string, build?: number, documents?: string[]): Promise<SessionResponse> {
        throw new Error('Function not implemented.');
    },
    updateChatMetadata: function (sessionId: string, metadata: Partial<ChatMetadata>): Promise<UpdateChatMetadataResponse> {
        throw new Error('Function not implemented.');
    },
    uploadDocument: function (): Promise<DocumentInfoModel> {
        throw new Error('Function not implemented.');
    },
    getDocuments: function (): Promise<DocumentsResponse> {
        throw new Error('Function not implemented.');
    },
    downloadDocuments: function (props: { fileIds: string[]; }): Promise<boolean> {
        throw new Error('Function not implemented.');
    },
    fetchDocuments: function (props: { fileIds: string[], sessionId?: string; }): Promise<DocumentsResponse> {
        throw new Error('Function not implemented.');
    },
    copyDocuments: function (props: { document_ids: string[]; }): Promise<DocumentsResponse> {
        throw new Error('Function not implemented.');
    },
    updateDocumentMetadata: function (props: { fileId: string, metadata: DocumentUpdateRequest; }): Promise<DocumentInfoModel> {
        throw new Error('Function not implemented.');
    },
    deleteDocuments: function (props: { fileIds: string[], sessionId: string; }): Promise<DeleteDocumentsResponse> {
        throw new Error('Function not implemented.');
    },
    deleteChat: function (sessionId: string): Promise<void> {
        throw new Error('Function not implemented.');
    },
    deleteAllChats: function (): Promise<void> {
        throw new Error('Function not implemented.');
    },
    prompt: function (message: string, conversationId: string): Promise<ChatMessageStream> {
        throw new Error('Function not implemented.');
    },
    dataSidePanel: function (sessionId: string, objectId: string): Promise<ComponentsData> {
        throw new Error('Function not implemented.');
    },

    setUserPreferences: function (listPatchMode: 'replace' | 'add-items' | 'remove-items', preferences: Partial<UserPreferences>): Promise<UserPreferences> {
        throw new Error('Function not implemented.');
    },

    getUserPreferences: function (): Promise<UserPreferences> {
        throw new Error('Function not implemented.');
    },

    tableData: function (sessionId: string, source: string, page?: number, params?: Record<string, string[]>): Promise<TableRowsResponse> {
        throw new Error('Function not implemented.');
    },
    messageLike: function (sessionId: string, messageId: string): Promise<void> {
        throw new Error('Function not implemented.');
    },
    messageDislike: function (sessionId: string, messageId: string): Promise<void> {
        throw new Error('Function not implemented.');
    },
    messageComment: function (sessionId: string, messageId: string, value: string): Promise<void> {
        throw new Error('Function not implemented.');
    },
    getChatMessagesFeedback: function (sessionId: string): Promise<ChatMessageFeedback[]> {
        throw new Error('Function not implemented.');
    },
    getMessageFeedback: function (sessionId: string, messageId: string): Promise<ChatMessageFeedback> {
        throw new Error('Function not implemented.');
    },
    messageResetVote: function (sessionId: string, messageId: string): Promise<void> {
        throw new Error('Function not implemented.');
    },
    messageInsights: function (traceId: string): Promise<ChatMessageTracesResponse> {
        throw new Error('Function not implemented.');
    },
    submitProductivityGain: function (sessionId: string, value: number): Promise<ProductivityGainResponse> {
        throw new Error('Function not implemented.');
    },

    events: {
        session: new NotImplementedEventHandler(),
        document: new NotImplementedEventHandler()
    }
};

export const ChatContext = createContext<IChatService>(defaultChatService);

const ChatProvider = (props: PropsWithChildren<ExtendedIChatService>) => {
    return (
        <ChatContext.Provider value={props}>
            {props.children}
        </ChatContext.Provider>
    );
};


export const useChat = () => useContext(ChatContext) as ExtendedIChatService;

export default ChatProvider;