import EventHandler from '../services/EventHandler';
import Description from '../ui/description/Description';
import useLabels from './useLabels';
import { ListActionType } from '../ui/list-actions/ListActions';
import { Icons } from '../ui/icon/icons/material';
import { TestIds } from '../mocks/ids';
import { DocumentEventTypes } from '../models/BackendEvents';
import { useErrorHandlerContext } from '../contexts/error-handler/ErrorContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getFinalDocumentStatus } from '../helpers/statusHelpers';


const uploadStatusEvent = new EventHandler<FileUploadStatus>();

export enum FileUploadStatus {
    Ready = 'ready',
    Error = 'invalid',
    Processing = 'processing'
}

export type FileType = {
    documentId: string;
    fileName: string;
    description: string;
    status: FileUploadStatus;
    size: number;
    extension: string;
    created: string;
};

export type FileListActionType = Pick<ListActionType, 'onClick'> & Partial<Omit<ListActionType, 'onClick'>>;

export type FileListActionsType = {
    edit?: FileListActionType;
    copy?: FileListActionType;
    remove?: FileListActionType;
    download?: FileListActionType;
    refresh?: FileListActionType;
};


export const useFileListActions = ({ edit, copy, remove, download, refresh }: FileListActionsType) => {
    const labels = useLabels();

    return [
        {
            label: labels.docListDownload,
            iconName: Icons.download,
            testId: TestIds.downloadButton,
            isHidden: !download?.onClick,
            ...download
        },
        {
            label: labels.docListDelete,
            iconName: Icons.delete,
            testId: TestIds.deleteButton,
            isHidden: !remove?.onClick,
            ...remove
        },
        {
            label: labels.docListEdit,
            iconName: Icons.edit,
            testId: TestIds.editButton,
            isHidden: !edit?.onClick,
            ...edit

        },
        {
            label: labels.docListCopy,
            iconName: Icons.copy,
            testId: TestIds.copyButton,
            isHidden: !copy?.onClick,
            ...copy
        },
        {
            label: '',
            iconName: Icons.refresh,
            testId: TestIds.refreshButton,
            isHidden: !refresh?.onClick,
            ...refresh
        }
    ].filter(Boolean) as ListActionType[];
};


export const useFileListState = ({ files, events }: { files: FileType[]; events?: Record<string, DocumentEventTypes>; }) => {
    const { removeError, registerError, errorId } = useErrorHandlerContext();

    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [loadingIds, setLoadingIds] = useState<string[]>([]);

    const handleErrorRemoval = useCallback(() => removeError(errorId), [errorId, removeError]);

    const handleErrorRegistering = useCallback((headline: string, description: string, details?: any) => {
        registerError({ [errorId]: { type: 'notification', headline, details, description } });
    }, [errorId, registerError]);

    const handleLoadingIdsChange = useCallback((ids: string[] = selectedIds) => {
        setLoadingIds(ids);
    }, [selectedIds]);

    const isAllSelected = useMemo(() => files.length === selectedIds.length && selectedIds.length > 0, [files, selectedIds]);
    const onSelectAll = useCallback(() => setSelectedIds(files.map(file => file.documentId)), [files]);
    const onDeselectAll = useCallback(() => setSelectedIds([]), []);

    const onToggleSelectId = useCallback((id: string) => {
        setSelectedIds(s => {
            if (s.indexOf(id) > -1) return s.filter(x => x !== id);
            return [...s, id];
        });
    }, []);

    useEffect(() => {
        const hasInvalidStatus = events && files.some(({ documentId, status }) => getFinalDocumentStatus({ documentId, status }, events) === FileUploadStatus.Error);
        if (hasInvalidStatus) uploadStatusEvent.invoke(FileUploadStatus.Error);
        else uploadStatusEvent.invoke(FileUploadStatus.Ready);
    }, [files, events]);

    const renderSelectedNames = useCallback(({ seeLess, seeMore }: { seeLess: string, seeMore: string; }) => <Description threshold={80} labels={{ seeLess, seeMore }} description={files.filter(file => selectedIds.includes(file.documentId)).map(item => item.fileName).join(', \n\n')} />, [files, selectedIds]);

    return {
        // Relevant for manual use
        errorId,
        handleErrorRemoval,
        handleErrorRegistering,
        handleLoadingIdsChange,
        renderSelectedNames,

        // Relevant for component prop spreeading
        files,
        loadingIds,
        selectedIds,
        onToggleSelectId,

        isAllSelected,
        onDeselectAll,
        onSelectAll,
    };
};
