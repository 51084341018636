import AdminService from '../services/AdminService';
import ChatService from '../../../../services/ChatService';
import { useMemo } from 'react';
import { useAuth } from '../../../../contexts/auth/AuthContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AdminResponseType, UtilityAudienceRequest, UtilityAudienceSearchRequest } from '../types/AdminServiceTypes';
import { AdminQueryKeys } from '../../../../shared/react-query/enums/AdminQueryKeys';
import { useBackendEvents } from '../../../../contexts/backend-events/BackendEventsContext';
import { RoutingPathsType } from '../helpers/routing';
import { queryClientInstance } from '../../../../shared/react-query';
import { AssetsService, GetImageRequestQuery } from '../../../../services/AssetService';

let adminServiceInstance: AdminService | null = null;

const useAdminService = () => {
    const { getAccessToken } = useAuth();

    const service = useMemo(() => {
        if (!adminServiceInstance) adminServiceInstance = new AdminService(getAccessToken);
        return adminServiceInstance;
    }, [getAccessToken]);
    return { service };
};

const useAdminChatService = () => {
    const { getAccessToken } = useAuth();
    const backendEvents = useBackendEvents();
    // TODO: Detach File upload from chat service to its own
    const service = useMemo(() => new ChatService(getAccessToken, backendEvents), [getAccessToken, backendEvents]);
    return { service };
};

const useAssetsService = () => {
    const { getAccessToken } = useAuth();
    // TODO: Move to right folder upload from chat service to its own
    const service = useMemo(() => new AssetsService(getAccessToken), [getAccessToken]);
    return { service };
};


// Documents

export const useGetDocument = ({ assistantId, versionNumber, fileIds, personaId }: RoutingPathsType['versions'] & { fileIds?: string[], personaId: string; }) => {
    const { service } = useAdminChatService();
    return useQuery({
        queryKey: AdminQueryKeys.assistantVersionFiles({ assistantId, versionNumber }),
        queryFn: () => service.getDocuments({ fileIds, personaId }),
    });
};

export const useUploadDocument = ({ assistantId, versionNumber }: RoutingPathsType['versions']) => {
    const { service } = useAdminChatService();
    return useMutation({
        mutationFn: service.uploadDocument,
        onSuccess: async () => {
            await queryClientInstance?.invalidateQueries({ queryKey: AdminQueryKeys.assistantVersionFiles({ assistantId, versionNumber }) });
        }
    });
};

export const useDownloadDocuments = () => {
    const { service } = useAdminChatService();
    return useMutation({
        mutationFn: service.downloadDocuments,
    });
};

export const useUpdateDocumentMetadata = ({ assistantId, versionNumber }: RoutingPathsType['versions']) => {
    const { service } = useAdminChatService();
    return useMutation({
        mutationFn: service.updateDocumentMetadata,
        onSuccess: async data => {
            queryClientInstance?.setQueryData(AdminQueryKeys.assistantVersionFiles({ assistantId, versionNumber }), data);
        }
    });
};

export const useDeleteDocuments = ({ assistantId, versionNumber }: RoutingPathsType['versions']) => {
    const { service } = useAdminChatService();
    return useMutation({
        mutationFn: service.deleteDocuments,
        onSuccess: async () => {
            queryClientInstance?.invalidateQueries({ queryKey: AdminQueryKeys.assistantVersionFiles({ assistantId, versionNumber }) });
        }
    });
};


// Images 
export const useGetImageFullUrl = (imagePath: string, options?: GetImageRequestQuery) => {
    const { service } = useAssetsService();
    return service.getImageFullUrl(imagePath, options);
};

export const useUploadImage = (props: RoutingPathsType['assistant']) => {
    const { service } = useAssetsService();
    return useMutation({
        mutationFn: service.uploadImage
    });
};


// Admin 

const useGetAdmin = <TSelect extends AdminResponseType, TResult = TSelect>({ select }: { select: (props: AdminResponseType) => TResult; }) => {
    const { service } = useAdminService();
    return useQuery({
        queryKey: AdminQueryKeys.permission(),
        queryFn: service.getAdminPermission,
        select
    });
};

export const useGetAdminPermission = () => {
    return useGetAdmin({
        select: ({ permissions }) => permissions
    });
};

export const useGetDefinitions = () => {
    const { service } = useAdminService();
    return useQuery({
        queryKey: AdminQueryKeys.definitions(),
        queryFn: service.getAdminDefinitions,
    });
};


// Utility

export const useGetUtilityAudience = (props: UtilityAudienceRequest) => {
    const { service } = useAdminService();
    return useQuery({
        queryKey: AdminQueryKeys.audience({ ...props }),
        queryFn: () => service.getUtilityAudience(props),
    });
};
export const useGetUtilityAudienceSearch = (props: UtilityAudienceSearchRequest) => {
    const { service } = useAdminService();
    return useQuery({
        queryKey: AdminQueryKeys.audienceSearch({ ...props }),
        queryFn: () => service.getUtilityAudienceSearch(props),

    });
};