import LoadingIcon from '../../loading-icon/LoadingIcon';
import React, { useCallback } from 'react';
import { getClassNames } from '../../../helpers/classHelpers';
import { IconProps, IconStyles, IconTheme } from '../Icon.types';
import { keyDownHelper } from '../../../helpers/keyDownHelper';
import styles from '../Icon.module.scss';

export default function Base({ id, testId, isClickable, iconStyle = IconStyles.outlined, iconName, size = 24, isLoading, className, title, isDisabled = false, theme = IconTheme.dark, onClick, dataAttributes }: IconProps) {
    const handleClick = useCallback(async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => !isDisabled && onClick?.(e), [isDisabled, onClick]);
    const handleKeyDown = useCallback(async (e: React.KeyboardEvent<HTMLElement>) => keyDownHelper(e, () => handleClick(e as any as React.MouseEvent<HTMLSpanElement, MouseEvent>)), [handleClick]);

    if (!iconName) return null;
    const clickable = ((onClick || isClickable) && !isDisabled);
    const classNames = getClassNames([iconStyle, className, clickable && styles.clickable, styles.icon, size && styles[`size-${size}`], theme && styles[`theme-${theme}`]]);
    if (isLoading) return <LoadingIcon className={getClassNames([styles.loader, classNames])} size={size} />;


    return <span
        id={id}
        tabIndex={(onClick || title) ? 0 : undefined}
        role={(onClick && !isDisabled) ? "button" : undefined}
        className={classNames}
        title={title}
        aria-disabled={isDisabled}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        data-testid={testId}
        {...dataAttributes}
    >
        {iconName}
    </span>;
};