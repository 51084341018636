import Adaptive from './Adaptive';
import { IconTheme, PreviewIconProps } from '../Icon.types';
import { Icons } from '../icons/material';
import { getClassNames } from '../../../helpers/classHelpers';
import styles from '../Icon.module.scss';

export default function Preview({ iconName, isFullWidth, imageSize }: PreviewIconProps) {
    return (
        <span className={getClassNames([styles['preview'], isFullWidth && styles['full-width'], !iconName && styles['placeholder']])}>
            <Adaptive imageSize={imageSize} title='' size={96} iconName={iconName ?? Icons.hideImage} theme={IconTheme.dark} />
        </span>
    );
};
