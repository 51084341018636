import { isNumber } from './typeHelpers';

function isObject(item: unknown) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

/** 
 * Filter out un-truthy values of the Object 
 * 
 *  @param {boolean} stringify - Enables stringification. __Currently only supports stringify'ing numbers__
 * */
export const filterObject = <T extends object, S extends boolean>(obj: T, stringify?: S) => {
    for (const key in obj) {
        const value = obj[key];
        if (stringify && isNumber(value)) (obj as any)[key] = value.toString();
        if (!value) delete obj[key];
    }

    return obj as S extends true ? { [K in keyof T]: string } : T;
};

/**Returns a deep copy of the object or the array that includes data only. Include properties that are supported by JSON */
export const cloneData = <T extends {} | []>(target: T) => {
    if (!target) return target;
    return JSON.parse(JSON.stringify(target)) as T;
};

export const deepMerge = (target: any, ...sources: any[]): any => {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                deepMerge(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return deepMerge(target, ...sources);
};