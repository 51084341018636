import { useCallback, useMemo } from 'react';
import { ChatInputModel } from '../../elements/chat-input';
import useStateRef from '../../hooks/useStateRef';

const useChatInfo = () => {
    const [state, setState] = useStateRef<Pick<ChatInputModel, "persona" | "version" | "build">>({});

    const setPersona = useCallback((persona?: string, version?: string, build?: number) => {
        if (!persona) {
            version = undefined;
            build = undefined;
        }

        setState({ persona, version, build });
    }, [setState]);

    const result = useMemo(() => {
        return {
            chatInfo: {
                persona: state.persona,
                version: state.version,
                build: state.build,
                setPersona
            }
        };
    }, [state.persona, state.version, state.build, setPersona]);
    return result;
};

export default useChatInfo;