import React, { type PropsWithChildren, type ReactNode, useMemo } from 'react';
import LoadingIcon from '../loading-icon/LoadingIcon';
import { getClassNames } from '../../helpers/classHelpers';
import { withUIState } from '../../contexts/ui-state/withUIState';
import style from './Button.module.scss';

export type ButtonProps = {
    id?: string;
    type?: ButtonType;
    label?: string;
    theme?: ButtonThemes;

    icon?: ReactNode;
    /**
     * Defaults to end
     */
    iconPosition?: ButtonIconPosition;
    title?: string;

    fullWidth?: boolean;
    isDisabled?: boolean;
    isLoading?: boolean;
    hideLoadingIcon?: boolean;

    className?: string;
    isSmall?: boolean;
    isLarge?: boolean;

    buttonWrapper?: (button: ReactNode) => ReactNode;
    /** In most cases it will return a 'void', but possible that it's an async function. */
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => unknown;
    onAsyncError?: (error: any) => void;
};

export enum ButtonThemes {
    primary = 'df-btn-primary',
    secondary = 'df-btn-secondary',
    textPrimary = 'df-text-btn df-text-btn-primary',
    textSecondary = 'df-text-btn df-text-btn-secondary',
    customTextLightDarkToDark = 'df-text-btn custom-light-dark-to-dark',
}

export enum ButtonType {
    button = 'button',
    submit = 'submit'
}

export enum ButtonIconPosition {
    beginning = 'beginning',
    end = 'end'
}


/** Component's description */
const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
    id,
    label,
    theme = ButtonThemes.primary,
    type,
    icon,
    iconPosition = ButtonIconPosition.end,
    title,

    fullWidth,
    isDisabled,
    isLoading,
    hideLoadingIcon,

    children,
    className,
    isSmall,
    isLarge,

    onClick,
}) => {
    const placeIconAtEnd = useMemo(() => iconPosition === ButtonIconPosition.end, [iconPosition]);

    const classNames = getClassNames([
        style.button,
        className,
        fullWidth && style['full-width'],
        theme,
        isSmall && 'df-btn-small',
        isLarge && 'df-btn-large',
        isLoading && style.loading,
        placeIconAtEnd ? style['_place-icon-end'] : style['_place-icon-start'],
    ]);

    const buttonLabel = useMemo(() => label || children, [label, children]);

    const renderIcon = useMemo(() => {
        return (
            <>
                {isLoading && !hideLoadingIcon && (
                    <span className={getClassNames(['df-icon'])} style={{ height: 24 }}>
                        {isLoading && <LoadingIcon />}
                    </span>
                )}
                {icon && !isLoading && (
                    <span className={getClassNames(['df-icon'])}>
                        {icon}
                    </span>
                )}
            </>
        );
    }, [isLoading, hideLoadingIcon, icon]);

    return (
        <button
            id={id}
            title={title}
            type={type}
            disabled={isLoading || isDisabled}
            onClick={onClick}
            className={classNames}
        >
            {buttonLabel && <span>{buttonLabel}</span>}
            {renderIcon}
        </button>
    );
};

export default withUIState(Button);
