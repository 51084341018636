import useLabels from "../../../../../../hooks/useLabels";
import Accordion from "../../../../../accordion/Accordion";
import CustomHero from "../../../../../custom-hero/CustomHero";
import Section from "../../../../../section/Section";
import DataTable from "./elements/DataTable";
import useEffectAsync from "../../../../../../hooks/useEffectAsync";
import useCachedPromise from '../../../../../../hooks/useCachedPromise';
import Loader from "../../../../../loader/Loader";
import { ComponentDataMap, ComponentsData } from "../../../../../../models/SidePanelTypes";
import { Fragment, ReactNode, useMemo, useState } from "react";
import { ObjectTypes } from "../../useActions";
import { generateUniqueString } from "@danfoss/mosaic-core";
import { useChat } from "../../../../../../contexts/chat/ChatContext";
import { useErrorHandlerContext } from "../../../../../../contexts/error-handler/ErrorContext";
import { ErrorHandler } from "../../../../../../contexts/error-handler/ErrorHandler";
import { TestIds } from "../../../../../../mocks/ids";
import { getClassNames } from '../../../../../../helpers/classHelpers';
import styles from "./Data.module.scss";

const renderer = ({ labels, data, chatId }: { labels: ReturnType<typeof useLabels>, data?: ComponentsData; chatId?: string; }) => {
    if (!data?.length) return null;
    const componentDataMap = {
        'accordion': ({ properties, children }: ComponentDataMap['accordion']) => {
            let childItems = children ?? [];
            const { text, subtitle } = properties;
            if (text || subtitle) {
                childItems = [{ component: 'section', properties: { title: "", text, subtitle, trimSpace: true }, children: [] }, ...childItems];
            }
            return <Accordion
                unmountHiddenContent
                className={styles.accordion}
                headline={properties.title}
                content={renderer({ labels, data: childItems, chatId })}
                isInitiallyOpen={!!properties.open}
            />;
        },
        'table': ({ properties }: ComponentDataMap['table']) => <DataTable className={styles.table} {...properties} chatId={chatId} />,
        'section': ({ properties, children }: ComponentDataMap['section']) => <Section className={getClassNames([styles.section, properties.trimSpace && styles.trimmed])} {...properties} children={renderer({ labels, data: children!, chatId })} />,
        'hero': ({ properties }: ComponentDataMap['hero']) => <CustomHero {...properties} />,
    } as Record<keyof ComponentDataMap, (props: ComponentDataMap[keyof ComponentDataMap]) => ReactNode>;

    return data?.map((x) => {
        const component = componentDataMap?.[x.component];
        return <Fragment key={generateUniqueString()}>
            {component?.(x as ComponentDataMap[keyof ComponentDataMap])}
        </Fragment>;
    });
};

export const Data = ({ objectId, objectType, chatId }: { objectId: string, objectType: ObjectTypes; chatId?: string; }) => {
    const labels = useLabels();
    const { dataSidePanel } = useChat();
    const { errorId, registerError, removeError, getError } = useErrorHandlerContext();
    const error = getError(errorId);

    const [data, setData] = useState<ComponentsData>();
    const [isLoading, setIsLoading] = useState(false);

    const getData = useCachedPromise(() => dataSidePanel(chatId || "", objectId), [chatId, objectId]);

    useEffectAsync(token => () => {
        removeError(errorId);
        if (token.cancelled) return;
        if (!objectId || !objectType) return;
        setIsLoading(true);


        if (token.cancelled) return;
        getData()
            .then(setData)
            .catch((err) => {
                registerError({ [errorId]: { type: 'notification', headline: labels.loadingErrorDescription, details: err } });
            })
            .finally(() => setIsLoading(false));

    }, [objectId, objectType, chatId]);

    const render = useMemo(() => renderer({ labels, data, chatId }), [data, labels, chatId]);


    return <div className={styles.wrapper} data-testid={TestIds.dataSidePanel}>
        {isLoading && <Loader />}
        {error && <ErrorHandler.Notification id={errorId} />}
        {!isLoading && !error && render}
    </div>;
};