import { FileUploadStatus } from '../hooks/useFileUpload';
import { DocumentEventTypes } from '../models/BackendEvents';

export enum Status {
    Success = 'success',
    Warning = 'warning',
    Danger = 'danger',
    Default = 'default',
}

export function getStatusLabelType(status: FileUploadStatus): Status {
    switch (status.toLowerCase()) {
        case FileUploadStatus.Ready.toLowerCase():
            return Status.Success;
        case FileUploadStatus.Processing.toLowerCase():
            return Status.Warning;
        case FileUploadStatus.Error.toLowerCase():
            return Status.Danger;
        default:
            return Status.Default;
    }
}

export const getFinalDocumentStatus = <T extends { documentId: string, status: FileUploadStatus; },>(document: T, events: Record<string, DocumentEventTypes>): FileUploadStatus => {
    const { documentId, status: fallbackStatus } = document;
    const event = events[documentId];

    let eventStatus = FileUploadStatus.Processing;
    if (event === DocumentEventTypes.processed) eventStatus = FileUploadStatus.Ready;
    else if (event === DocumentEventTypes.failed) eventStatus = FileUploadStatus.Error;

    return events[documentId] ? eventStatus : fallbackStatus;
};