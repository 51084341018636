import { CustomSelectOptionType } from '@danfoss/mosaic-core';
import { LlmRawOption, LlmUIOption } from '../models/types';
import { isString } from './typeHelpers';

export const rawLlmToUI = (rawLlm: LlmRawOption): LlmUIOption => ({
    description: rawLlm.description,
    sort: rawLlm.sort,
    label: rawLlm.title,
    value: rawLlm.key,
    characterLimit: rawLlm.characterLimit,
});

export const propertyAsOption = <T extends Record<string, any>, K extends keyof T>(item: T, property: K): CustomSelectOptionType => {
    const value = item[property];
    return {
        value,
        label: value,
    };
};

export const valueToOption = (value: string | number): CustomSelectOptionType => {
    if (!isString(value)) value = value.toString();

    return {
        value,
        label: value,
    };
};