import ButtonAsync from '../../ui/button/ButtonAsync';
import Modal, { ModalProps } from '../../ui/modal/Modal';
import Button, { ButtonThemes } from '../../ui/button/Button';
import { ErrorHandler } from '../../contexts/error-handler/ErrorHandler';
import style from './FileListActionsModal.module.scss';

export type DocumentUpdateModalProps = {
    modal?: ModalProps;
    children: React.ReactNode;

    isOpen: boolean;

    close: {
        label: string;
        onClick: () => void;
    };

    submit: {
        label: string;
        isDisabled?: boolean;
        onClick: () => Promise<void>;
    };

    errorId: string;

};

const FileListActionsModal: React.FC<DocumentUpdateModalProps> = ({ modal, children, isOpen, close, submit, errorId }) => {
    return (
        <Modal
            closeOnEscape
            closeOnBackdropClick
            isOpen={isOpen}
            className={style['file-list-actions-modal']}
            onClose={close.onClick}
            size='medium'
            {...modal}
            body={(
                <>
                    {children}
                    <ErrorHandler.Notification id={errorId} />
                </>
            )}
            footer={(
                <>
                    {close && <Button {...close} theme={ButtonThemes.textPrimary} />}
                    {submit && <ButtonAsync {...submit} />}
                </>
            )}
        />
    );
};

export default FileListActionsModal;