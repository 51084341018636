import Checkbox from '../checkbox/Checkbox';
import LoadingIcon from '../loading-icon/LoadingIcon';
import React, { FC } from 'react';
import styles from './ListItem.module.scss';

export type ListItemProps = {
    id: string;

    header: React.ReactNode;
    footer: React.ReactNode;
    children?: React.ReactNode;

    itemsSelected?: string[];
    headerClassName?: string;
    childrenClassName?: string;
    footerClassName?: string;

    isLoading?: boolean;
    isDisabled?: boolean;
    onItemSelect?: (id: string) => void;
};

const ListItem: FC<ListItemProps> = ({ id, isDisabled, header, footer, children, itemsSelected, headerClassName, childrenClassName, footerClassName, isLoading, onItemSelect }) => {
    return (
        <li className={styles['list-item']}>
            {onItemSelect && (
                <Checkbox
                    isDisabled={isDisabled}
                    isChecked={!!itemsSelected?.includes(id)}
                    onChange={() => onItemSelect(id)}
                />
            )}

            {(header || children || footer) && <div>
                {header && <div className={headerClassName}>{header}</div>}
                {children && <div className={childrenClassName}>{children}</div>}
                {footer && <div className={footerClassName}>{footer}</div>}
            </div>}

            {isLoading && (
                <div className={styles['loader-overlay']}>
                    <LoadingIcon size={36} />
                </div>
            )}
        </li>
    );
};

export default ListItem;