import { delimit } from '../helpers/listsHelpers';

const adminLabels = {
    id: 'Id',
    key: 'Key',
    copy: 'Copy',
    delete: 'Delete',
    add: 'Add',
    create: 'Create',
    save: 'Save',
    cancel: 'Cancel',
    role: 'Role',
    avatar: 'Avatar',
    icon: 'Landing Page Icon',
    name: 'Name',
    type: 'Type',
    edit: 'Edit',
    model: 'Model',
    seeMore: 'See more',
    seeLess: 'See less',
    confirm: 'Confirm',
    routeKey: 'Route key',
    description: 'Description',
    saveChanges: 'Save changes',
    createNew: 'Create new',
    createVersionHeadline: 'Create an Assistant Version',
    autoSaveHeadline: 'Enable autosave, your changes will be saved every few seconds once you stop typing. or you can individually pick desired version in current: Assistant > Share > Beta section',
    requiredHelperText: 'This field is required',

    publishBeta: 'Publish Beta',
    autoPublishBeta: 'Automatically publish this version for testing',
    publishBetaDescription: 'Publish Beta version to the testing audience, granting them early access to new features to explore, identify issues, and provide feedback.',
    createDraftDescription: 'Create a new draft version to work on features, improvements, or bug fixes without affecting the live version.',
    publishLive: 'Publish Live ',
    publishLiveDescription: 'Release this version to all users, replacing the previous version with the new one. This makes the latest features available to everyone, ensuring a seamless experience while introducing enhancements to improve performance and usability.',

    published: 'Published',
    beta: 'Beta',
    live: 'Live',
    draft: 'Draft',

    //
    sectionsHeadline: 'Sections',
    sectionsDescription: 'You can select between sections of interest.',
    assistantsHeadline: 'Assistants',
    assistantsDescription: 'Create, manage and publish assistants, their versions and configurations.',
    assistantsNoAccess: 'You do not have access to assistants, please contact your administrator.',

    //Models
    modelsHeadline: 'Models',
    modelsDescription: 'Create and manage models and their configurations.',
    modelsNoAccess: 'You do not have access to models, please contact your administrator.',

    // Tools 
    toolsHeadline: 'Tools',
    toolsDescription: 'Manage audiences and their permissions seamlessly.',
    toolsNoAccess: 'You do not have access to tools, please contact your administrator.',
    toolConfigurationHeadline: 'Configurations',
    toolAudiencesHeadline: 'Audiences',


    //
    toolTab: 'Tool',
    settingsTab: 'Settings',
    promptTab: 'Prompt',
    knowledgeTab: 'Knowledge',
    changelogTab: 'Changelog',
    publishTab: 'Publish/Revise',

    // Elements
    historyPlaceholder: 'Assistants',
    addTextPlaceholder: 'Add text',
    tableSearchPlaceholder: 'Search',
    tableSearchNoResult: 'No result found on your search criteria',
    listingNameInputTile: 'Assistant name',
    listingDescriptionInputTile: 'Description',
    removeIcon: 'Remove icon',
    iconUploadDisclaimer: () => <div>Alternatively, you can request us to extend the icon set with additional icons from <a href='https://marella.me/material-icons/demo/'>this library</a> or provide your own custom SVG icons</div>,

    // Layout
    admin: 'Admin',

    // Models
    addToolTitle: 'Tools',
    addMemberTypeTitle: 'Type',
    addMemberRoleTitle: 'Role',
    addMember: 'Add member',

    addToolHeadline: 'Add Tool',
    editAudienceHeadline: 'Edit audience',

    addMemberSearchTitle: 'Search',
    addMemberHeadline: 'Add audience',
    noVersionsFound: 'You have no versions created yet',
    noToolsAvailable: 'No tools has been added yet',
    createVersionActionTitle: 'Source',
    createVersionVersionTitle: 'Version',
    createVersionSelectionPlaceholder: 'New version',
    createAssistantHeadline: 'Create an Assistant',
    createAssistantToggle: 'Manually define assistant key',
    addMemberNoResult: 'No result found on your search criteria',
    editConfiguration: 'Edit configuration',
    addMemberSearchNote: 'Search to find desired result',
    createVersionVersionPlaceholder: 'Version number (x.x.x-tag-x)',
    createVersionIncorrectFormat: 'Invalid version format, please use the following format: (major.minor.patch)',
    keyIncorrectFormat: 'Invalid input. Value can contain only numbers, lower case characters, dashes and underscores',

    listingEnableInAdvancedAssistant: 'Enable this model appearance in Advanced assistant',
    listingMaxCharacters: 'Maximum character amount',
    listingOrder: 'Order in the list',

    modelConfigSelectedProvider: 'Selected model provider..',
    modelConfigProviderLabel: 'Provider',
    modelConfigDeploymentLabel: 'Deployment',
    modelConfigWorkspaceLabel: 'Workspace',
    modelConfigEndpointLabel: 'Endpoint',
    modelConfigStreamingLabel: 'Streaming',


    deleteTitle: 'Delete item',
    deleteHeadline: 'Delete',
    deleteDescription: 'Are you sure you want to delete following items: ',

    semVerCta: 'Need help with naming?',
    semVerHeadline: `We're using Semantic Versioning (SemVer)`,
    semVerDescription: `
    This versioning method helps us to version software using <u>MAJOR.MINOR.PATCH</u> plus optional pre-release and build metadata tags <u>(e.g., 1.2.3-alpha.1)</u>.
    <br/>
    <br/>

    <b>Versioning rules:</b><br/>
    MAJOR: Increase for breaking changes (e.g., 1.0.0 → 2.0.0).<br/>
    MINOR: Increase for new features that don't break compatibility (e.g., 1.2.0 → 1.3.0).<br/>
    PATCH: Increase for bug fixes or small improvements (e.g., 1.2.3 → 1.2.4).<br/>
    `,
    noVersionsAvailable: 'No versions available, please publish a version first.',




    // Routes
    assistantsRouteCta: 'Create an assistant',
    modelsRouteCta: 'Create a model',
    changelogRouteReleaseNotesTitle: 'Release notes',
    changelogRouteHighlightsTitle: 'Highlights',
    evaluationRouteHeadline: 'Evaluation',
    evaluationRouteDisclaimer: 'The evaluation feature is in development, please use the chat UI to Evaluate',
    evaluationRouteCta: 'open in a new tab',
    knowledgeRouteToolsCta: 'Add tool',
    settingsRouteListingTitle: 'Listing',
    settingsRouteConfigurationsTitle: 'Configurations',
    settingsRouteAudiencesTitle: 'Audiences',
    settingsRouteConversationStartersTitle: 'Conversation starters',
    settingsRouteVersionsTitle: 'Versions',
    shareRouteLiveTitle: 'Live',
    shareRouteBetaTitle: 'Beta',
    shareRouteMembersTitle: 'Members',
    toolsRouteAssistantHeadline: 'Assistant tools',
    toolsRouteAssistantContent: 'Assistant tools config form',
    promptRouteMacrosTitle: 'Macros',
    promptRouteMacrosBlankInfo: 'Macros are available onces instructions are added or tools, documents or are included in the knowledge tab',
    knowledgeRouteDocumentsTitle: (revision?: number) => delimit(['Documents', revision && `Revision (${revision})`], ' - '),
    knowledgeRouteModelsTitle: (revision?: number) => delimit(['Models', revision && `Revision (${revision})`], ' - '),
    knowledgeRouteToolsTitle: (revision?: number) => delimit(['Tools', revision && `Revision (${revision})`], ' - '),
    promptRouteSystemTitle: (revision?: number) => delimit(['System prompt', revision && `Revision (${revision})`], ' - '),
    promptRouteInstructionsTitle: (revision?: number) => delimit(['Instructions', revision && `Revision (${revision})`], ' - '),
    instructionNameMissingName: '*',
    instructionsNameLabel: 'Name',
    instructionsDescriptionLabel: 'Description',
    instructionsTemplateLabel: 'Template',
    noInstructionsYet: 'No instructions yet',
    createInstruction: 'Create instruction',
    deleteInstruction: (name: string) => `Delete instruction: ${name}`,
    publishRevisionTitle: "Publish Current Revision",
    publishBetaTitle: (revision?: number) => delimit(['Publish Beta', revision && `Revision (${revision})`], ' - '),
    publishLiveTitle: (revision?: number) => delimit(['Publish Live', revision && `Revision (${revision})`], ' - '),
    createRevisionTitle: 'Create New Revision',


    // versionData?.build?.latest
    createNewRevisionCta: (currentRevision?: number) => delimit(['Create New', currentRevision && `Revision (${currentRevision + 1})`], ' - '),
    temperature: 'Temperature',

    // errors
    promptSavingError: 'Unable autosave prompt',
    createAudienceErrorHeadline: 'Error creating audience',
    createAudienceErrorMessage: 'Please try again, and contact us if the problem persists',
    loadAudienceErrorHeadline: 'Error loading data',
    loadAudienceErrorMessage: 'Please try reloading the page and contact us if the problem persists',
    generalErrorMessage: 'Please try again, and contact us if the problem persists',

    createToolErrorHeadline: 'Error creating tool',
    createToolErrorMessage: 'Please try again, and contact us if the problem persists',

    deleteErrorHeadline: 'Unable to delete selected items',
    deleteErrorMessage: 'Please try again, and contact us if the problem persists',

    createAssistantErrorHeadline: 'Unable to create an assistant',
    createAssistantErrorMessage: 'Please try again, and contact us if the problem persists',

    createVersionErrorHeadline: 'Unable to create a version',
    createVersionErrorMessage: 'Please try again, and contact us if the problem persists',

    editConfigurationErrorHeadline: 'Unable to edit configuration',
    editConfigurationErrorMessage: 'Please try again, and contact us if the problem persists',

    updateAudienceErrorHeadline: 'Unable to update audience(s)',
    updateAudienceErrorMessage: 'Please try again, and contact us if the problem persists',

    globalSaveErrorHeadline: 'Unable to save changes',
    globalSaveErrorMessage: 'Please try again, and contact us if the problem persists',

    createModelHeadline: 'Create a model',
    createModelToggle: 'Manually define assistant key',
};

export default adminLabels;