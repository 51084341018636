import React, { useCallback } from 'react';
import { DfSlider } from '@danfoss/mosaic-react';
import { DfSliderCustomEvent } from '@danfoss/mosaic-core/dist/types/components';
import style from './Slider.module.scss';

type Props = {
    value: number;

    min?: number;
    max?: number;
    step?: number;
    showCaptions?: boolean;
    isDisabled?: boolean;

    onChange?: (value: number) => void;
    /** Note: due to a bug in Mosaic, this will NOT fire the first time a change happens. */
    onChangeComplete?: (value: number) => void;
};

const Slider: React.FC<Props> = ({ value, min, max, step = 1, showCaptions, isDisabled, onChange, onChangeComplete, }) => {
    const onChangeHandler = useCallback((e: DfSliderCustomEvent<number>) => {
        onChange?.(e.detail);
    }, [onChange]);

    const onCompleteHandler = useCallback((e: DfSliderCustomEvent<number>) => {
        onChangeComplete?.(e.detail);
    }, [onChangeComplete]);

    return (
        <DfSlider
            disabled={isDisabled}
            className={style.slider}
            sliderValue={value}
            minValue={min}
            maxValue={max}
            step={step}

            showCaptions={showCaptions}

            onValueChange={onChangeHandler}

            onChangeComplete={onCompleteHandler}
        />
    );
};

export default Slider;