import { RoutingPathsType } from '../../../features/dashboards/admin/helpers/routing';
import { WithRevision } from '../../../features/dashboards/admin/sections/assistants/types/AssistantsServiceTypes';
import { delimit } from '../../../helpers/listsHelpers';

export const AdminQueryKeys = {
    all: 'admin',

    permission: () => [AdminQueryKeys.all, 'permission'],
    definitions: () => [AdminQueryKeys.all, 'definitions'],
    assistants: () => [AdminQueryKeys.all, 'assistants'],
    assistant: ({ assistantId }: RoutingPathsType['assistant']) => [AdminQueryKeys.all, 'assistant', assistantId],
    assistantAssetImage: ({ assistantId }: RoutingPathsType['assistant']) => [AdminQueryKeys.all, 'assistant-asset-image', assistantId],
    assistantVersions: ({ assistantId }: RoutingPathsType['assistant']) => [AdminQueryKeys.all, 'versions', assistantId],
    assistantVersion: ({ assistantId, versionNumber }: RoutingPathsType['versions']) => [AdminQueryKeys.all, 'versions', assistantId, versionNumber],
    assistantVersionFiles: ({ assistantId, versionNumber }: RoutingPathsType['versions']) => [AdminQueryKeys.all, 'assistant-version-files', assistantId, versionNumber],
    assistantVersionRevision: ({ assistantId, versionNumber, revisionKey }: RoutingPathsType['versions'] & WithRevision) => [AdminQueryKeys.all, 'revision', assistantId, versionNumber, revisionKey?.toString()],


    // Admin Assistants
    audience: ({ user, group, role }: { user: string[], group: string[], role: string[]; }) => [AdminQueryKeys.all, 'audience', delimit(user), delimit(group), delimit(role)],
    audienceSearch: ({ type, keyword = '', top }: { type: string, keyword?: string; top?: number; }) => [AdminQueryKeys.all, 'audience-search', top, type, keyword],

    // Admin Models
    models: () => [AdminQueryKeys.all, 'models'],
    model: ({ modelId }: RoutingPathsType['model']) => [AdminQueryKeys.all, 'model', modelId],

    // Admin Tools
    tools: () => [AdminQueryKeys.all, 'tools'],
    tool: ({ toolId }: RoutingPathsType['tool']) => [AdminQueryKeys.all, 'tool', toolId],
};