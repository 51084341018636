import useStorage from '../../hooks/useStorage';
import { useCallback, useMemo } from 'react';
import { RibbonMessageProps } from '../../ui/ribbon-message/RibbonMessage';
import { useAuth } from '../auth/AuthContext';
import { AuthenticationState, useAppInfo } from '../app-info/AppInfoContext';
import { sortWith } from '../../helpers/listsHelpers';

type Message = {
    id: string;
    label: string;
    urlLabel?: string;
    url?: string;
    visible: boolean;
    schedule?: {
        starts: string;
        ends: string;
    };
    priority?: number;
};
// TODO: Move messages to the server.
const MESSAGES: Message[] = [
    // {
    //     id: 'chatbot-interface-change',
    //     label: 'The Danfoss AI Chatbot interface is being updated, with the changes set to roll out soon. To learn more',
    //     urlLabel: 'click here',
    //     url: 'https://danfoss.sharepoint.com/sites/AI/SitePages/Danfoss-AI-Chatbot.aspx',
    //     visible: true,
    //     schedule: {
    //         starts: '2024-11-01T00:00:00.0Z',
    //         ends: '2024-12-02T00:00:00.0Z',
    //     },
    //     priority: 1,
    // },
    // {
    //     id: 'chatbot-document-functionality',
    //     label: 'We are currently updating the document upload functionality. Please note that the feature may be unstable between 16:00 and 17:00 CET.',
    //     visible: true,
    // }
];

const STORAGE_TYPE = "local";
const STORAGE_SCOPE = "notifications";
const STORAGE_KEY_SEEN = 'seen';

const useRibbonMessage = (): { ribbonMessage?: RibbonMessageProps; } => {
    const { user } = useAuth();
    const { authenticationState } = useAppInfo();
    const isLoggedIn = useMemo(() => !!user && authenticationState === AuthenticationState.Authenticated, [user, authenticationState]);

    const { useStorageState } = useStorage(STORAGE_SCOPE, STORAGE_TYPE);

    const [state, setState] = useStorageState<string[] | undefined>(STORAGE_KEY_SEEN, []);

    const handleClose = useCallback((id: string) => {
        return () => {
            setState(cur => {
                cur = cur ?? [];
                if (cur.includes(id))
                    return cur;

                return [...cur, id];
            });
        };
    }, [setState]);

    const message = useMemo<RibbonMessageProps | undefined>(() => {
        const seen = state ?? [];
        const messages = MESSAGES.filter(m => (
            // Messages are displayed to logged in users only 
            // We can consider making it possible to configure which users can see certain message or whether a message is visible to anonymous users.
            isLoggedIn &&
            // Include messages that are marked as visible
            m.visible &&
            // Do not include messages that have been seen already
            !seen.includes(m.id) &&
            (
                // If message has no schedule, show it.
                !m.schedule ||
                (
                    // Otherwise, check if the message schedule is in range
                    (
                        !m.schedule.starts || new Date(m.schedule.starts).getTime() < Date.now()
                    ) &&
                    (
                        !m.schedule.ends || new Date(m.schedule.ends).getTime() > Date.now()
                    )
                )
            )
        ));

        if (!messages.length)
            return;
        const sortedMessage = sortWith(messages, m => m.priority || -1, true);

        const message = sortedMessage[0];
        return {
            ...message,
            handleClose: handleClose(message.id)
        };

    }, [state, handleClose, isLoggedIn]);


    return {
        ribbonMessage: message,
    };
};

export default useRibbonMessage;