import { SvgIcons } from '../ui/icon/icons/svg';

// UI Constants
export const portalId = 'portal-outlet';
export enum MediaSize {
    xl = 1919.5,
    lg = 1439.5,
    md = 1023.5,
    sm = 719.5,
    xs = 599.5,
    xss = 359.5
}

export enum MediaOrientation {
    landscape = 'landscape',
    portrait = 'portrait'
}
// Chat/session metadata
export const maxSubjectLength = 100;


// LLM Options
export const defaultTemperature = .5;
export const maxMessageLength = 10000;
export const defaultPersonaKey = 'basic-gpt';

export const tenderAssistantKey = 'dcs-tender-assistant';
export const pimAssistantKey = 'dcs-pim-assistant';

export const advancedAssistantKey = 'advanced';
export const advancedAssistantRoute = advancedAssistantKey;

export const defaultPersonaIcon: SvgIcons = SvgIcons.Bot;


// Localizing
export const defaultCulture = 'en';


export const danfossAssetsBaseUrl = 'https://assets.danfoss.com/';
export const danfossAssetsBaseUrlObj = new URL(danfossAssetsBaseUrl);

export const WHITELISTED_FILE_SOURCES = [danfossAssetsBaseUrl];
export const sidePanelElementId = 'side-panel';