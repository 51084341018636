import React from 'react';
import Routing from './router/Routing';
import useSetViewportHeight from './hooks/useViewportHeight';
import Alert from './ui/alert/Alert';
import Modal from './ui/modal/Modal';
import Tours from './features/tours/Tours';
import AdminPermissionsProvider from './features/permissions/scopes/admin/contexts/AdminPermissionsProvider';
import { portalId } from './constants/consts';
import { ErrorHandler } from './contexts/error-handler/ErrorHandler';
import { GLOBAL_MODAL_ERROR_ID } from './contexts/error-handler/ErrorContext';
import './App.scss';

const App: React.FC = () => {
    useSetViewportHeight();
    return <AdminPermissionsProvider>
        <Routing />
        <Tours />

        <div id={portalId}></div>
        <ErrorHandler.Modal className={'global-error-modal'} id={GLOBAL_MODAL_ERROR_ID} />

        <div id="eager-loader" style={{ display: 'none' }}>
            <Modal />
            <Alert />
        </div>
    </AdminPermissionsProvider>;
};

export default App;