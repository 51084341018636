import React, { memo, useCallback, useMemo, useState } from 'react';
import TemperatureSlider from '../temperature-slider/TemperatureSlider';
import useLabels from '../../hooks/useLabels';
import Divider from '../../ui/divider/Divider';
import InputLabel from '../../ui/input-label/InputLabel';
import Accordion from '../../ui/accordion/Accordion';
import Select from '../../ui/select/Select';
import { MediaSize, advancedAssistantKey, defaultTemperature } from '../../constants/consts';
import { useMediaSize } from '../../hooks/useMediaSize';
import { AccordionVariantEnum } from '@danfoss/mosaic-core';
import { ChatInputModel, ChatInputPropsBase } from './models';
import { TestIds } from '../../mocks/ids';
import styles from './ChatInput.module.scss';

export type ChatInputOptionsProps = ChatInputPropsBase & Pick<ChatInputModel, "llm" | "persona" | "version" | "build" | "temperature">;
export type ChatInputVersionOptionsProps = Omit<ChatInputOptionsProps, "llm" | "temperature">;

export const ChatInputVersionOptions: React.NamedExoticComponent<ChatInputVersionOptionsProps> = memo(({ persona, version, build, onModelMerge, personaOptions, isInputDisabled }) => {
    const labels = useLabels();
    const l = useMemo(() => {
        return {
            version: labels.version
        };
    }, [labels]);
    const personaVersions = useMemo(() => {
        const p = personaOptions.find(x => x.key === persona);
        const o: { label: string, version: string, build: number, value: string; }[] = [];
        p?.versions.forEach(version => {
            version.revisions.forEach(revision => {
                o.push({
                    label: revision.label,
                    value: revision.label,
                    version: version.version,
                    build: revision.build
                });
            });
        });
        return o;
    }, [personaOptions, persona]);

    const versionOptions = useMemo(() => {
        if (personaVersions.length < 2)
            return null;
        return <div>
            <InputLabel
                label={l.version}
                isDisabled={isInputDisabled}
                className={styles['select-label']}
            />
            <Select
                testId={TestIds.dfSelect}
                isDisabled={isInputDisabled}
                showSelectedOptions
                options={personaVersions}
                value={personaVersions.find(x => x.version === version && x.build === build)?.label ?? personaVersions?.[0]?.label}
                onChange={(e) => onModelMerge({ version: e?.version as string, build: e?.build as number })}
            />
        </div>;
    }, [isInputDisabled, l.version, version, build, onModelMerge, personaVersions]);

    return versionOptions;
});

export const ChatInputOptions: React.NamedExoticComponent<ChatInputOptionsProps> = memo((props) => {
    const { llm, temperature, persona, onModelMerge, llmOptions, isInputDisabled } = props;
    const labels = useLabels();
    const l = useMemo(() => {
        return {
            accordionHl: labels.advancedSettings,
            beta: labels.beta,
            model: labels.model,
            version: labels.version,
            modelIconTitle: labels.modelIconTitle,
            modalHl: labels.modelDescriptions,
            choosePersona: labels.choosePersona,
            choosePersonaDescriptions: labels.choosePersonaDescriptions,
            choosePersonaIconTitle: labels.choosePersonaIconTitle
        };
    }, [labels]);


    const isMobile = useMediaSize(ms => ms <= MediaSize.sm);
    const advancedPersonaSelected = persona === advancedAssistantKey;


    const slider = <TemperatureSlider value={(temperature ?? defaultTemperature) * 10} onChange={v => onModelMerge({ temperature: parseFloat((v * 0.1).toFixed(3)) })} isDisabled={isInputDisabled} />;

    const llmModalBody = <LlmModalBody llmOptions={llmOptions} />;
    const llmUIOptions = useMemo(() => llmOptions.map(x => ({ ...x, 'data-testid': TestIds.dfSelectOption })), [llmOptions]);

    const versionOptions = <ChatInputVersionOptions {...props} />;

    const renderOptions = !advancedPersonaSelected ? versionOptions : <>
        {versionOptions}
        <div>
            <InputLabel
                testId={TestIds.advancedOptionsModal}
                iconTestId={TestIds.advancedOptionsModalIcon}
                label={l.model}
                tooltipPlacement={'top-start'}
                modal={{ headline: l.modalHl, body: llmModalBody, }}
                iconTitle={l.modelIconTitle}
                isDisabled={isInputDisabled}
                className={styles['select-label']}
            />
            <Select
                testId={TestIds.dfSelect}
                isDisabled={isInputDisabled}
                showSelectedOptions
                options={llmUIOptions}
                value={llmUIOptions.find(x => x.value === llm)?.label ?? llmOptions?.[0]?.label}
                onChange={(e) => onModelMerge({ llm: e?.value as string })}
            />
        </div>
        {isMobile && <>
            <br />
            <Accordion
                isDisabled={isInputDisabled}
                className={styles.accordion}
                isInitiallyOpen={true}
                headline={l.accordionHl}
                content={<>
                    <Divider />
                    {slider}
                </>} />
        </>}
        {!isMobile && slider}
    </>;


    return <div className={styles['settings-container']}>
        {renderOptions}

    </div>;
});

const LlmModalBody: React.NamedExoticComponent<Pick<ChatInputPropsBase, "llmOptions">> = memo(({ llmOptions }) => {
    const [openAccordions, setOpenAccordions] = useState<number[]>([]);
    const onToggleAccordionHandler = useCallback((index: number) => {
        setOpenAccordions(prev => {
            const isOpen = prev.includes(index);
            return isOpen ? prev.filter(i => i !== index) : [...prev, index];
        });
    }, []);

    return <>
        {
            llmOptions.map((llm, i) => <Accordion
                key={i}
                headline={llm.label}
                content={<p className={styles['accordion-desc']}>{llm.description}</p>}

                externalState={{
                    isOpen: openAccordions.includes(i),
                    onToggle: (isOpen: boolean) => onToggleAccordionHandler(i)
                }}
                variant={AccordionVariantEnum.WithDivider}
            />)
        }
    </>;
});