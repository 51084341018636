import ListItem, { ListItemProps } from '../list-item/ListItem';
import styles from './ListItems.module.scss';

export type Props = {
    items: ListItemProps[];
    loadingItemIds?: string[];
} & Pick<ListItemProps, 'isDisabled' | 'itemsSelected' | 'onItemSelect' | 'childrenClassName' | 'footerClassName' | 'headerClassName'>;

const ListItems = ({ items, isDisabled, itemsSelected, onItemSelect, loadingItemIds, ...rest }: Props) => {
    return (
        <ul className={styles['list-items']}>
            {[...items].reverse().map((props, i) => {
                const isLoadingItem = loadingItemIds?.includes(props.id);

                return (
                    <ListItem
                        key={i}
                        isDisabled={isDisabled}
                        isLoading={isLoadingItem}
                        itemsSelected={itemsSelected}
                        onItemSelect={onItemSelect}
                        {...rest}
                        {...props}
                    />
                );
            })}
        </ul>
    );
};

export default ListItems;