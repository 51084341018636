import React from 'react';
import useScrollLock from '../../hooks/useScrollLock';
import { ListItemContentProps } from '../../ui/list/List';
import { getClassNames } from '../../helpers/classHelpers';
import { DateGroup } from '../../helpers/dateHelpers';
import style from './Sidebar.module.scss';

type Props = {
    id?: string;
    className?: string;
    scrollClassName?: string;
    direction?: 'left' | 'right';
    isOpen?: boolean;
    content: {
        className?: string;
        scrollChildren?: React.ReactNode;
        headerChildren?: React.ReactNode;
        footerChildren?: React.ReactNode;
    }[];

    enforceLeftSidebarAsModal?: boolean;
    onCloseSidebar?: () => void;
};

export type EnrichedListItem = ListItemContentProps & {
    date: string;
    dateGroup: DateGroup;
};

const Sidebar: React.FC<Props> = ({ id, enforceLeftSidebarAsModal, scrollClassName, className, direction = 'left', isOpen, content, onCloseSidebar }) => {
    useScrollLock(isOpen);

    return (<>
        <aside id={id} className={getClassNames([className, style[direction], style.sidebar, isOpen && '_sidebar-open', enforceLeftSidebarAsModal && style['enforce-modal-style']])}>
            <div className={style['content-wrapper']}>
                {content.map(({ className, footerChildren, headerChildren, scrollChildren }, i) => {
                    const isCurrent = i === content.length - 1;

                    return (
                        <div key={i} className={getClassNames([style['content'], isCurrent && style.visible])}>
                            {headerChildren}
                            <div className={getClassNames([style.scroll, scrollClassName, className])}>
                                {scrollChildren}
                            </div>
                            {footerChildren}
                        </div>
                    );
                })}
            </div>
        </aside>
        <div onClick={onCloseSidebar} className={style.backdrop}></div>
    </>);
};

export default Sidebar;