import ListActions, { ListActionType } from '../list-actions/ListActions';

type Props = {
    actions: ListActionType[];
    isAllSelected: boolean;
    isDisabled?: boolean;
    onSelectAll: () => void;
    onDeselectAll: () => void;
};

const FileListActions = ({ isDisabled, actions, isAllSelected, onSelectAll, onDeselectAll }: Props) => {
    return (
        <ListActions
            withUIState
            actions={actions}
            onDeselectAll={onDeselectAll}
            onSelectAll={onSelectAll}
            isDisabled={isDisabled}
            isAllSelected={isAllSelected}
        />
    );
};

export default FileListActions;