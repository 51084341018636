import EnvConfigHelper from '../helpers/envConfigHelper';
import { HTTPDataService } from './HTTPDataService';
import { dictionaryToQueryString } from '../helpers/routeHelpers';


export type GetImageRequestQuery = {
    width?: number; //min 1;
    height?: number; //min 1;
    mode?: 'min' | 'max';
    background?: string; // hex color e.g ffffff
    cache?: boolean;
};


type UploadImageRequestBody = { file: File; };
type UploadImageResponse = { image_path: string; };

type ClearImageCacheResponse = void;

interface IAssetsServiceTypes {
    getImageFullUrl: (imagePath?: string) => string | undefined;
    uploadImage: (props: UploadImageRequestBody) => Promise<UploadImageResponse>;
    clearImageCache: () => Promise<ClearImageCacheResponse>;
}

export class AssetsService extends HTTPDataService implements IAssetsServiceTypes {
    baseURL = EnvConfigHelper.get('api-base-url', '');

    constructor(public getAccessToken: () => Promise<string | undefined>) {
        super();
    }

    getImageFullUrl = (imagePath?: string, options?: GetImageRequestQuery) => {
        if (!imagePath)
            return;
        return `${this.baseURL}${imagePath}${dictionaryToQueryString({ dict: options ?? {} })}`;
    };

    uploadImage = (body: UploadImageRequestBody) => {
        const formData = new FormData();
        formData.append("file", body.file);

        const uri = 'api/assets/image/upload';
        return this.post<UploadImageResponse>(uri, undefined, formData);
    };

    clearImageCache = () => {
        const uri = 'api/assets/image/cache/clear';
        return this.post<ClearImageCacheResponse>(uri);
    };
}