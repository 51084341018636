import React, { type ReactNode } from 'react';
import { getClassNames } from '../../helpers/classHelpers';
import style from './Hero.module.scss';

type Props = {
    mainContent?: ReactNode;
    sideContent?: ReactNode;
};

const Hero: React.FC<Props> = ({ mainContent, sideContent, }) => {
    return (
        <div className={getClassNames([style.hero, 'df-hero-card content-box'])}>
            <section className={getClassNames([style.image, 'image'])}></section>
            <section className='df-container'>
                <div className='content'>
                    {mainContent && <div className='main-content'>
                        {mainContent}
                    </div>}
                    {sideContent && <div className='side-content'>
                        {sideContent}
                    </div>}
                </div>
            </section>
        </div>
    );
};

export default Hero;