import EnvConfigHelper from '../../../../helpers/envConfigHelper';
import { filterObject } from '../../../../helpers/objectHelper';
import { HTTPDataService } from '../../../../services/HTTPDataService';
import { AdminDefinitionsType, AdminResponseType, IAdminServiceTypes, UtilityAudienceRequest, UtilityAudienceResponse, UtilityAudienceSearchRequest, UtilityAudienceSearchResponse } from '../types/AdminServiceTypes';


class AdminService extends HTTPDataService implements IAdminServiceTypes {
    baseURL = EnvConfigHelper.get('api-base-url', '');

    constructor(public getAccessToken: () => Promise<string | undefined>) {
        super();
    }

    getAdminDefinitions = () => {
        const uri = 'api/admin/definitions';
        return this.get<AdminDefinitionsType>(uri);
    };

    getAdminPermission = () => {
        const uri = 'api/admin';
        return this.get<AdminResponseType>(uri);
    };


    getUtilityAudience = (body: UtilityAudienceRequest) => {
        const uri = 'api/admin/utils/audience';
        return this.post<UtilityAudienceResponse>(uri, undefined, body,);
    };
    getUtilityAudienceSearch = ({ type, keyword, top }: UtilityAudienceSearchRequest) => {
        const uri = 'api/admin/utils/audience/search';
        const query = filterObject({ type, keyword, top }, true);
        return this.get<UtilityAudienceSearchResponse>(uri, query);
    };
}

export default AdminService;