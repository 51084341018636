import Loader from '../../../ui/loader/Loader';
import { Suspense, lazy } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { AdminRoutes } from './helpers/routing';

// ADMIN TODO: Consider moving routes to each section of their own

const AdminRoute = lazy(() => import('./routes/AdminRoute'));
const RootLayout = lazy(() => import('./layout/root/RootLayout'));
const NotFound = lazy(() => import('../../../elements/not-found/NotFound'));

const AssistantsLayout = lazy(() => import('./sections/assistants/layout/assistants/AssistantsLayout'));
const SettingsLayout = lazy(() => import('./sections/assistants/layout/settings/SettingsLayout'));
const VersionLayout = lazy(() => import('./sections/assistants/layout/version-layout/VersionLayout'));
const PromptRoute = lazy(() => import('./sections/assistants/routes/PromptRoute'));
const AssistantsRootRoute = lazy(() => import('./sections/assistants/routes/AssistantsRootRoute'));
const ChangelogRoute = lazy(() => import('./sections/assistants/routes/ChangelogRoute'));
const PublishRoute = lazy(() => import('./sections/assistants/routes/PublishRoute'));
const KnowledgeRoute = lazy(() => import('./sections/assistants/routes/KnowledgeRoute'));
const EvaluateRoute = lazy(() => import('./sections/assistants/routes/EvaluateRoute'));
const SettingsRoute = lazy(() => import('./sections/assistants/routes/SettingsRoute'));
const ShareRoute = lazy(() => import('./sections/assistants/routes/ShareRoute'));

const ModelLayout = lazy(() => import('./sections/models/layout/model/ModelLayout'));
const ModelsLayout = lazy(() => import('./sections/models/layout/models/ModelsLayout'));
const ModelsRootRoute = lazy(() => import('./sections/models/routes/ModelsRootRoute'));
const ModelRoute = lazy(() => import('./sections/models/routes/ModelRoute'));

const ToolLayout = lazy(() => import('./sections/tools/layout/tool/ToolLayout'));
const ToolsLayout = lazy(() => import('./sections/tools/layout/tools/ToolsLayout'));
const ToolsRootRoute = lazy(() => import('./sections/tools/routes/ToolsRootRoute'));
const ToolRoute = lazy(() => import('./sections/tools/routes/ToolRoute'));


const Routes = () => {
    const isAuthorized = true; // TODO: Implement this, possibly make a hook that will be used here and in the LoggedInTemplate file

    const wrapInSuspense = (component: JSX.Element) => {
        // Cannot be memoized
        return (
            <Suspense fallback={<Loader isRelative />}>
                {component}
            </Suspense>
        );
    };

    if (!isAuthorized) return <Route path='/*' element={wrapInSuspense(<NotFound />)} />;

    return (
        // The context for Assistants are provided in the AssistantsLayout, but at this higher level, we are also showing an assistants list
        //
        <Route path={AdminRoutes.admin} element={wrapInSuspense(<RootLayout />)}>
            <Route index element={wrapInSuspense(<AdminRoute />)} />

            {/* Assistants */}
            <Route path={AdminRoutes.assistants} element={<AssistantsLayout />}>
                <Route index element={wrapInSuspense(<AssistantsRootRoute />)} />

                <Route path={AdminRoutes.assistant} element={wrapInSuspense(<SettingsLayout />)}>
                    {/* Navigate to initial category */}
                    <Route index element={<Navigate to={AdminRoutes.settings} replace={true} />} />

                    <Route path={AdminRoutes.evaluate} element={wrapInSuspense(<EvaluateRoute />)} />
                    <Route path={AdminRoutes.share} element={wrapInSuspense(<ShareRoute />)} />
                    <Route path={AdminRoutes.settings} element={wrapInSuspense(<SettingsRoute />)} />
                </Route>

                <Route path={AdminRoutes.versions} element={wrapInSuspense(<VersionLayout />)}>
                    {/* Navigate to initial category */}
                    <Route index element={<Navigate to={AdminRoutes.prompt} replace={true} />} />

                    <Route path={AdminRoutes.prompt} element={wrapInSuspense(<PromptRoute />)} />
                    <Route path={AdminRoutes.changelog} element={wrapInSuspense(<ChangelogRoute />)} />
                    <Route path={AdminRoutes.publish} element={wrapInSuspense(<PublishRoute />)} />
                    <Route path={AdminRoutes.knowledge}>
                        <Route index element={wrapInSuspense(<KnowledgeRoute />)} />
                        <Route path={AdminRoutes.knowledgeTool} element={wrapInSuspense(<ToolRoute />)} />
                    </Route>
                </Route>
            </Route>

            {/* Models */}
            <Route path={AdminRoutes.models} element={wrapInSuspense(<ModelsLayout />)}>
                <Route index element={wrapInSuspense(<ModelsRootRoute />)} />

                <Route path={AdminRoutes.model} element={wrapInSuspense(<ModelLayout />)}>
                    <Route index element={wrapInSuspense(<ModelRoute />)} />
                </Route>
            </Route>

            {/* Tools */}
            <Route path={AdminRoutes.tools} element={wrapInSuspense(<ToolsLayout />)}>
                <Route index element={wrapInSuspense(<ToolsRootRoute />)} />

                <Route path={AdminRoutes.tool} element={wrapInSuspense(<ToolLayout />)}>
                    <Route index element={wrapInSuspense(<ToolRoute />)} />
                </Route>
            </Route>

        </Route >
    );
};

export default Routes;

// const TBD = () => <div>TBD</div>;