import React, { HTMLInputTypeAttribute, useMemo } from 'react';
import InputLabel from '../input-label/InputLabel';
import { getClassNames } from '../../helpers/classHelpers';
import { generateUniqueId } from '@danfoss/mosaic-core';
import { withUIState } from '../../contexts/ui-state/withUIState';

export type InputProps = {
    id?: string;
    label?: string;

    value: string;
    onChange?: (value: string) => void;
    onBlur?: (value: string) => void;
    placeholder?: string;
    type?: HTMLInputTypeAttribute;

    errorVisibleAfterBlur?: boolean;
    hasError?: boolean;
    helperText?: string;

    isReadonly?: boolean;
    isLoading?: boolean;
    isDisabled?: boolean;
    className?: string;
    containerClassName?: string;
};

const Input: React.FC<InputProps> = ({ id, label, value, onChange, onBlur, placeholder, type = 'text', hasError, helperText, isDisabled, isLoading, isReadonly, className, containerClassName, errorVisibleAfterBlur }) => {
    const localId = useMemo(() => id ?? generateUniqueId(), [id]);

    const [blurred, setBlurred] = React.useState(false);

    return (
        <div className={containerClassName}>
            {label && <InputLabel className={'df-field-label'} htmlFor={localId} label={label} isDisabled={isDisabled} />}
            <input
                id={localId}
                type={type}
                placeholder={placeholder}
                className={getClassNames(['df-input', (hasError && (!errorVisibleAfterBlur || blurred)) && 'error', className])}
                disabled={isDisabled || isLoading}
                readOnly={isReadonly}
                value={value}
                onChange={(e) => onChange?.(e.target.value)}
                onBlur={(e) => {
                    onBlur?.(e.target.value);
                    setBlurred(true);
                }}
            />
            {helperText && (!errorVisibleAfterBlur || blurred) && <label className={getClassNames(['df-helper-text', hasError && 'error'])}>{helperText}</label>}
        </div>
    );
};

export default withUIState(Input);