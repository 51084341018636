import React, { useState, useCallback, useRef } from "react";
import labels from "../../data/labels";
import { IconStyles } from "../icon/Icon.types";
import { getClassNames } from "../../helpers/classHelpers";
import { Icons } from '../icon/icons/material';
import { Icon } from '../icon/Icon';
import { generateUniqueId } from '@danfoss/mosaic-core';
import { getExtension } from '../../helpers/fileHelpers';
import { keyDownHelper } from '../../helpers/keyDownHelper';
import { TestIds } from '../../mocks/ids';
import { TourFileUploadTargets } from '../../features/tours/hooks/useTourFileUploads';
import style from "./FileInputDropArea.module.scss";

export type FileInputDropAreaProps = {
    isDisabled?: boolean;
    testId?: string;
    onFilesSelected?: (files: FileList) => void;
    onInvalidFiles?: () => void;
    accept?: string[];
    multiple?: boolean;
};

const isValidFileExtension = (fileName: string, extensions?: string[]) => {
    const { extension } = getExtension(fileName);
    return extensions?.includes(extension.toLowerCase());
};

const FileInputDropArea: React.FC<FileInputDropAreaProps> = ({ isDisabled, onFilesSelected, onInvalidFiles, testId, accept, multiple }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const uniqueId = testId ?? generateUniqueId();
    const [isDragging, setIsDragging] = useState(false);

    // Reset the input value to allow selecting the same file again
    const resetInputValue = useCallback(() => {
        if (!inputRef.current) return;
        inputRef.current.value = '';
    }, []);

    const createFileList = useCallback((files: File[]): FileList => {
        const dataTransfer = new DataTransfer();
        files.forEach(file => dataTransfer.items.add(file));
        return dataTransfer.files;
    }, []);

    const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        if (isDisabled) return;
        event.preventDefault();
        setIsDragging(true);
    }, [isDisabled]);

    const handleDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);
    }, []);

    const handleFileListValidation = useCallback((files?: FileList) => {
        if (!files || !onFilesSelected) return;

        // If no accept prop is provided, accept all files
        if (!accept?.length) return onFilesSelected(createFileList(Array.from(files)));

        // Filter out files with invalid extensions
        const validFiles = Array.from(files).filter(file => isValidFileExtension(file.name, accept));
        if (validFiles.length > 0) onFilesSelected(createFileList(validFiles));
        else onInvalidFiles?.();


    }, [accept, createFileList, onFilesSelected, onInvalidFiles]);

    const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        if (isDisabled) return;
        event.preventDefault();
        setIsDragging(false);

        const files = event.dataTransfer.files;
        if (files) handleFileListValidation(files);
        resetInputValue();
    }, [handleFileListValidation, isDisabled, resetInputValue]);

    const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (isDisabled) return;
        const files = event.target.files;
        if (files) handleFileListValidation(files);
        resetInputValue();
    }, [handleFileListValidation, isDisabled, resetInputValue]);

    return (
        <div
            id={TourFileUploadTargets.uploadBox}
            data-testid={TestIds.fileDropArea}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            className={getClassNames([style["df-file-drop"], isDragging && style["df-file-drop--dragging"]])}
            aria-disabled={isDisabled}
        >
            <p className={style["file-drop-content"]}>
                <Icon.Base
                    title=''
                    iconName={Icons.upload}
                    iconStyle={IconStyles.filled}
                    className={getClassNames([style["df-icon"]])}
                    isDisabled={isDisabled}
                />
                {labels.dropFilesAreaUploadText}
                <input
                    type="file"
                    data-testid={TestIds.fileInput}
                    ref={inputRef}
                    id={uniqueId}
                    multiple={multiple}
                    className="df-btn-file"
                    tabIndex={-1}
                    onChange={handleFileChange}
                    aria-label={labels.dropFilesAreaBrowseText}
                    accept={accept?.join(",")}
                    disabled={isDisabled}
                />
                <label
                    tabIndex={0}
                    onKeyDown={e => !isDisabled && keyDownHelper(e, () => inputRef.current?.click())}
                    htmlFor={uniqueId}
                    aria-controls={uniqueId}
                    aria-disabled={isDisabled}
                >
                    {labels.dropFilesAreaBrowseText}
                </label>
            </p>
        </div>
    );
};

export default FileInputDropArea;