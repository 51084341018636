import { UseMutationResult } from '@tanstack/react-query';

// All of the properties are optional!
export const defaultQueryOptions = {
    /**
    * Set this to `false` to disable automatic refetching when the query mounts or changes query keys.
    * To refetch the query, use the `refetch` method returned from the `useQuery` instance.
    * Defaults to `true`.
    */
    enabled: true,

    /**
     * The time in milliseconds after data is considered stale.
     * If set to `Infinity`, the data will never be considered stale.
     */
    staleTime: Infinity,

    /**
     * If set to `true`, the query will refetch on window focus if the data is stale.
     * If set to `false`, the query will not refetch on window focus.
     * If set to `'always'`, the query will always refetch on window focus.
     * If set to a function, the function will be executed with the latest data and query to compute the value.
     * Defaults to `true`.
     */
    refetchOnWindowFocus: false,

    /**
     * If set to `false`, the query will not be retried on mount if it contains an error.
     * Defaults to `true`.
    */
    retryOnMount: false,

    /**
     * If `false`, failed queries will not retry by default.
     * If `true`, failed queries will retry infinitely., failureCount: num
     * If set to an integer number, e.g. 3, failed queries will retry until the failed query count meets that number.
     * If set to a function `(failureCount, error) => boolean` failed queries will retry until the function returns false.
    */
    retry: false


    // ======================================================================
    // ======================================================================
    // ==== OTHER PROPS THAT ARE NOT INCLUDED IN THIS FUNCTION DEFAULTS: ====
    // ======================================================================


    /**
     * If set to a number, the query will continuously refetch at this frequency in milliseconds.
     * If set to a function, the function will be executed with the latest data and query to compute a frequency
     * Defaults to `false`.
     */
    // refetchInterval: false,

    /**
     * If set to `true`, the query will continue to refetch while their tab/window is in the background.
     * Defaults to `false`.
     */
    // refetchIntervalInBackground: false,

    /**
     * If set to `true`, the query will refetch on reconnect if the data is stale.
     * If set to `false`, the query will not refetch on reconnect.
     * If set to `'always'`, the query will always refetch on reconnect.
     * If set to a function, the function will be executed with the latest data and query to compute the value.
     * Defaults to `true`.
     */
    // refetchOnReconnect: true,

    /**
     * If set to `true`, the query will refetch on mount if the data is stale.
     * If set to `false`, will disable additional instances of a query to trigger background refetches.
     * If set to `'always'`, the query will always refetch on mount.
     * If set to a function, the function will be executed with the latest data and query to compute the value
     * Defaults to `true`.
     */
    // refetchOnMount: true,

    /**
     * If set, the component will only re-render if any of the listed properties change.
     * When set to `['data', 'error']`, the component will only re-render when the `data` or `error` properties change.
     * When set to `tracked`, access to properties will be tracked, and the component will only re-render when one of the tracked properties change.
     */
    // notifyOnChangeProps: [],

    /**
     * If set, the component will not re-render if any of the listed properties change.
     */
    // notifyOnChangePropsExclusions: [],

    /**
     * This callback will fire any time the query successfully fetches new data or the cache is updated via `setQueryData`.
     */
    // onSuccess?: (data: TData) => void;

    /**
     * This callback will fire if the query encounters an error and will be passed the error.
     */
    // onError?: (err: TError) => void;

    /**
     * This callback will fire any time the query is either successfully fetched or errors and be passed either the data or error.
     */
    // onSettled?: (data: TData | undefined, error: TError | null) => void;

    /**
     * Whether errors should be thrown instead of setting the `error` property.
     * If set to `true` or `suspense` is `true`, all errors will be thrown to the error boundary.
     * If set to `false` and `suspense` is `false`, errors are returned as state.
     * If set to a function, it will be passed the error and the query, and it should return a boolean indicating whether to show the error in an error boundary (`true`) or return the error as state (`false`).
     * Defaults to `false`.
     */
    // useErrorBoundary?: boolean | ((error: TError, query: Query<TQueryFnData, TError, TQueryData, TQueryKey>) => boolean);

    /**
     * This option can be used to transform or select a part of the data returned by the query function.
     */
    // select?: (data: TQueryData) => TData;

    /**
     * If set to `true`, the query will suspend when `status === 'loading'`
     * and throw errors when `status === 'error'`.
     * Defaults to `false`.
     */
    // suspense?: boolean;

    /**
     * Set this to `true` to keep the previous `data` when fetching based on a new query key.
     * Defaults to `false`.
     */
    // keepPreviousData?: boolean;

    /**
     * If set, this value will be used as the placeholder data for this particular query observer while the query is still in the `loading` data and no initialData has been provided.
     */
    // placeholderData?: TQueryData | PlaceholderDataFunction<TQueryData>;

    /**
     * If set, the observer will optimistically set the result in fetching state before the query has actually started fetching.
     * This is to make sure the results are not lagging behind.
     * Defaults to `true`.
     */
    // optimisticResults?: boolean;

    /**
     * Set this to `false` to disable structural sharing between query results.
     * Defaults to `true`.
     */
    // structuralSharing?: boolean;

    /**
     * This function can be set to automatically get the previous cursor for infinite queries.
     * The result will also be used to determine the value of `hasPreviousPage`.
     */
    // getPreviousPageParam?: GetPreviousPageParamFunction<TQueryFnData>;

    /**
     * This function can be set to automatically get the next cursor for infinite queries.
     * The result will also be used to determine the value of `hasNextPage`.
     */
    // getNextPageParam?: GetNextPageParamFunction<TQueryFnData>;
    // _defaulted?: boolean;

    /**
     * Additional payload to be stored on each query.
     * Use this property to pass information that can be used in other places.
     */
    // meta?: QueryMeta;

    // retryDelay?: RetryDelayValue<TError>;
    // cacheTime?: number;
    // isDataEqual?: (oldData: TData | undefined, newData: TData) => boolean;
    // queryHash?: string;
    // queryKeyHashFn?: QueryKeyHashFunction<TQueryKey>;
    // initialData?: TData | InitialDataFunction<TData>;
    // initialDataUpdatedAt?: number | (() => number | undefined);
    // behavior?: QueryBehavior<TQueryFnData, TError, TData>;
};

// export const DefaultMutationOptions = {
//     mutationFn?: MutationFunction<TData, TVariables>;
//     mutationKey?: MutationKey;
//     variables?: TVariables;
//     onMutate?: (variables: TVariables) => Promise<TContext | undefined> | TContext | undefined;
//     onSuccess?: (data: TData, variables: TVariables, context: TContext | undefined) => Promise<unknown> | void;
//     onError?: (error: TError, variables: TVariables, context: TContext | undefined) => Promise<unknown> | void;
//     onSettled?: (data: TData | undefined, error: TError | null, variables: TVariables, context: TContext | undefined) => Promise<unknown> | void;
//     retry?: RetryValue<TError>;
//     retryDelay?: RetryDelayValue<TError>;
//     _defaulted?: boolean;
//     meta?: MutationMeta;
//     useErrorBoundary?: boolean | ((error: TError) => boolean);
// };

export const isMutation = (query: any): query is UseMutationResult<any, any, any, any> => {
    return 'mutate' in query;
};