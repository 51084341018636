import Checkbox from '../checkbox/Checkbox';
import { FC } from 'react';
import { Icon } from '../icon/Icon';
import { IconTheme } from '../icon/Icon.types';
import { getClassNames } from '../../helpers/classHelpers';
import { Icons } from '../icon/icons/material';
import { TestIds } from '../../mocks/ids';
import { withUIState } from '../../contexts/ui-state/withUIState';
import styles from './ListActions.module.scss';


export type ListActionType = {
    id: string;
    label: string;
    iconName: Icons;
    onClick: () => void;
    isDisabled?: boolean;
    isLoading?: boolean;
    isHidden?: boolean;
    testId?: TestIds;
};

type Props = {
    isAllSelected?: boolean;
    onSelectAll?: () => void;
    onDeselectAll?: () => void;
    actions: ListActionType[];
    isDisabled?: boolean;
    isLoading?: boolean;
};

const ListActions: FC<Props> = ({ isDisabled, isLoading, actions, isAllSelected, onDeselectAll, onSelectAll }) => {
    return (
        <div className={styles['list-actions']}>
            {onDeselectAll && onSelectAll && <Checkbox isChecked={isAllSelected} onChange={isAllSelected ? onDeselectAll : onSelectAll} isDisabled={isDisabled || isLoading} />}
            <div className={styles.actions}>
                {actions?.map((action, i) => <ListActionLabel key={i} {...action} isLoading={isLoading} />)}
            </div>
        </div>
    );
};

export default withUIState(ListActions);


const ListActionLabel = ({ iconName, isDisabled, isLoading, isHidden, label, testId, onClick }: ListActionType) => {
    if (isHidden) return null;

    return (
        <label data-testid={testId} className={getClassNames([(isDisabled || isLoading) && styles['_disabled']])} onClick={isDisabled || isLoading ? undefined : onClick}>
            <Icon.Base theme={IconTheme.inherit} size={18} iconName={iconName} title={''} isClickable={!isDisabled && !isLoading} />
            {label}
        </label>
    );
};