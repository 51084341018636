import AdminPermissionsContext from './AdminPermissionsContext';
import { PropsWithChildren, useMemo } from 'react';
import { AdminPermissionsContextValue } from '../types/Context';
import { PermissionAction, Permission } from '../../../models/Permissions';
import { canPerformActionFactory } from '../../../helpers';
import { useGetAdminPermission } from '../../../../dashboards/admin/hooks/useAdminService';


// Not sure yet if data should be passed as a prop or if it should be read from a data context
// For now we are hardcoding it
const AdminPermissionsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { data } = useGetAdminPermission();

    const canView = useMemo<PermissionAction>(() => {
        if (!data) return () => false;
        return canPerformActionFactory(data, Permission.Manage);
    }, [data]);

    const showAdminLink = useMemo<PermissionAction>(() => {
        if (Object.values(data ?? []).some(x => x?.length)) return () => true;
        return () => false;
    }, [data]);

    const contextValue = useMemo<AdminPermissionsContextValue>(() => ({ showAdminLink, canView }), [canView, showAdminLink]);

    return (
        <AdminPermissionsContext.Provider value={contextValue}>
            {children}
        </AdminPermissionsContext.Provider>
    );
};

export default AdminPermissionsProvider;