import React, { ComponentType, forwardRef, ForwardRefExoticComponent, useMemo } from 'react';
import { useUIState } from './hooks';
import { resolvePropAndContextValue } from './helpers';

/** These _can_ be props from the component itself. */
type WithUIStateProps = WithUIStateFlagProp & {
    isDisabled?: boolean;
    isReadonly?: boolean;
    isLoading?: boolean;
};

export type WithUIStateFlagProp = {
    withUIState?: boolean;
};


/** Utility type for the HOC's return type. */
type WithUIStateHOC<P> = ForwardRefExoticComponent<
    React.PropsWithoutRef<
        P & WithUIStateProps & WithUIStateFlagProp
    > & React.RefAttributes<any>
>;

export const withUIState = <P extends object>(WrappedComponent: ComponentType<P & WithUIStateProps>): WithUIStateHOC<P> => {

    const ComponentWithUIState = forwardRef<any, P & WithUIStateProps & WithUIStateFlagProp>((props, ref) => {

        // Loading the UI state from the context.
        const { isDisabled: contextDisabled, isReadonly: contextReadonly, isLoading: contextLoading } = useUIState();

        // Combine states from props and context.
        const withUIStateProps = useMemo<WithUIStateProps>(() => {
            if (!props.withUIState) return {};
            const isDisabled = resolvePropAndContextValue(props?.isDisabled, contextDisabled);
            const isReadonly = resolvePropAndContextValue(props?.isReadonly, contextReadonly);
            const isLoading = resolvePropAndContextValue(props?.isLoading, contextLoading);
            return {
                isDisabled,
                isReadonly,
                isLoading,
            };
        }, [contextDisabled, contextLoading, contextReadonly, props?.isDisabled, props?.isLoading, props?.isReadonly, props.withUIState]);

        // Pass the combined states to the wrapped component.
        return <WrappedComponent ref={ref} {...props} {...withUIStateProps} />;
    });

    ComponentWithUIState.displayName = `withUIState(${WrappedComponent.displayName || WrappedComponent.name || 'UnnamedComponent'})`;

    return ComponentWithUIState;
};