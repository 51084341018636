import React, { CSSProperties, forwardRef, useMemo } from 'react';
import InputLabel from '../input-label/InputLabel';
import { getClassNames } from '../../helpers/classHelpers';
import { BaseUIType } from '../../models/UITypes';
import { generateUniqueId } from '@danfoss/mosaic-core';
import { withUIState } from '../../contexts/ui-state/withUIState';
import style from './Textarea.module.scss';

export type TextareaProps = BaseUIType & {
    label?: string;
    value: string;
    id?: string;
    autofocus?: boolean;
    onChange: (value: string) => void;
    onKeyUp?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    placeholder?: string;
    rows?: number;
    resize?: CSSProperties['resize'];
    withIcon?: boolean;
    isRequired?: boolean;
    isInvalid?: boolean;
    isDisabled?: boolean;
    isLoading?: boolean;
    isReadonly?: boolean;

    className?: string;
    /**Default 1000 */
    maxLength?: number;
};

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(({ id, autofocus, rows = 1, label, value, onChange, onKeyDown, placeholder, resize = 'none', withIcon, isRequired, isInvalid, isDisabled, isLoading, isReadonly, maxLength, className, testId, }, ref) => {
    const localId = useMemo(() => id ?? generateUniqueId(), [id]);

    const textarea = <textarea
        id={localId}
        autoFocus={autofocus}
        maxLength={maxLength ?? 1000}
        data-testid={testId}
        rows={rows}
        disabled={isDisabled || isLoading}
        readOnly={isReadonly}
        placeholder={placeholder}
        className={getClassNames(['df-input', style.textarea, className, withIcon && style['with-icon']])}
        ref={ref}
        value={value}
        aria-invalid={isInvalid}
        onKeyDown={onKeyDown}
        onChange={(e) => onChange(e.target.value)}
        style={{ resize }}
    />;

    const withLabel = <div>
        {label && <InputLabel className='df-field-label' htmlFor={localId} label={label} isRequired={isRequired} />}
        {textarea}
    </div>;

    return label ? withLabel : textarea;
});

export default withUIState(Textarea);