import useHistory from './useChatsHistory';
import useCatalogFavorites from './useCatalogFavorites';
import useChatInfo from './useChatInfo';
import useRibbonMessage from './useRibbonMessage';
import { createContext, useContext } from 'react';

export type IAdaptiveProvider = {} & ReturnType<typeof useChatInfo> & ReturnType<typeof useHistory> & ReturnType<typeof useCatalogFavorites & ReturnType<typeof useRibbonMessage>>;

const defaultAdaptiveContextValues: IAdaptiveProvider = {
    chatInfo: {
        persona: '',
        version: undefined,
        build: undefined,
        setPersona: () => { throw new Error('Function not implemented.'); },
    },
    chatHistory: {
        items: [],
        set: () => { throw new Error('Function not implemented.'); },
        reload: () => { throw new Error('Function not implemented.'); },
    },
    catalogFavorites: {
        personas_pinned: [],
        firstFavorite: '',
        disallowPinning: false,
        toggleFavorite: () => { throw new Error('Function not implemented.'); },
        setFavorites: () => { throw new Error('Function not implemented.'); },

        personas_disable_auto_pin: [],
        setPersonasDisableAutoPin: () => { throw new Error('Function not implemented.'); },
        setAsFavoriteOnInitialPrompt: () => { throw new Error('Function not implemented.'); },
    }
};

export const AdaptiveContext = createContext<IAdaptiveProvider>(defaultAdaptiveContextValues);

export const useAdaptive = () => useContext(AdaptiveContext) as IAdaptiveProvider;