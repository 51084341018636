import useInfo from '../../../hooks/useInfo';
import useLabels from '../../../hooks/useLabels';
import AsyncMarkdownWrapper from '../../../ui/markdown-wrapper/AsyncMarkdownWrapper';
import { Fragment, useState } from 'react';
import { DfTabContent, DfTabHeader, DfTabs } from '@danfoss/mosaic-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnchorHeadline } from './AnchorHeadline';
import { ReleaseTimeline } from './ReleaseTimeline';
import { formatDate } from '../../../helpers/dateHelpers';
import { useAppInfo } from '../../../contexts/app-info/AppInfoContext';
import style from '../Information.module.scss';
import { advancedAssistantKey } from '../../../constants/consts';


export const AiAssistantChanges = () => {
    const labels = useLabels();
    const location = useLocation();
    const navigate = useNavigate();
    const { getPersonaVersionReleaseNotes } = useAppInfo();
    const { allowedPersonaOptions } = useInfo();
    const [activeTab, setActiveTab] = useState('');

    const handleTabChange = (e: CustomEvent) => {
        setActiveTab(e.detail.tabId);
        navigate({ ...location, search: `tab=${e.detail.tabId}`, hash: location.hash }, { replace: true });
    };

    const filteredPersonaOptions = allowedPersonaOptions.filter(assistant => assistant.key !== advancedAssistantKey);

    if (!filteredPersonaOptions.length) return null;

    return <div>
        <AnchorHeadline headline={labels.aiAssistantHeadline} />
        <DfTabs className={style['sticky-tabs']} isCustomLinkNavigation={true} activeTabId={activeTab} onActiveTabChange={handleTabChange}>
            {filteredPersonaOptions
                .map((assistant) => {
                    const key = assistant.key;

                    return <Fragment key={key}>
                        <DfTabHeader slot='header' tabId={key}>
                            {assistant.title}
                        </DfTabHeader>
                        <br />
                        <DfTabContent slot='content' tabId={key}>
                            <>
                                <br />
                                {assistant.versions.map((release, index) => <ReleaseTimeline
                                    key={index}
                                    assistantKey={key}
                                    version={release.version}
                                    date={release.date ? formatDate(release.date) : "-"}
                                    content={(isOpen) => isOpen && <AsyncMarkdownWrapper url={() => getPersonaVersionReleaseNotes(key, release.version)} />} />)}
                            </>
                        </DfTabContent>
                    </Fragment>;
                })}
        </DfTabs>
    </div>;
};