import PageLayout from '../../layouts/page-layout/PageLayout';
import Button from '../../ui/button/Button';
import Hero from '../../ui/hero/Hero';
import useLabels from '../../hooks/useLabels';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoute } from '../../router/Routing';
import { FC, useMemo } from 'react';

type Props = {
    excludeLayout?: boolean;
};

const NotFound: FC<Props> = ({ excludeLayout }) => {
    const { pathname } = useLocation();
    const labels = useLabels();
    const navigate = useNavigate();

    const inner = useMemo(() => {
        return <Hero
            mainContent={<h1>404: {pathname}</h1>}
            sideContent={<Button
                label={labels.goBack}
                onClick={() => navigate(AppRoute.root)}
            />}
        />;
    }, [labels.goBack, navigate, pathname]);

    if (excludeLayout) return inner;
    return <PageLayout>
        {inner}
    </PageLayout>;
};

export default NotFound;