import React from 'react';
import { Icon } from '../../ui/icon/Icon';
import { defaultPersonaIcon } from '../../constants/consts';
import { getClassNames } from '../../helpers/classHelpers';
import { PersonaOption } from '../../models/types';
import { AdaptiveIconProps, IconTheme } from '../../ui/icon/Icon.types';
import style from './ChatWelcome.module.scss';

type Props = {
    avatar?: AdaptiveIconProps['iconName'];
    persona?: PersonaOption;
};

const ChatWelcome: React.FC<Props> = ({ avatar, persona }) => {
    const icon = persona?.icon || persona?.avatar || avatar;

    if (!persona) return null;
    return (
        <div className={style['chat-welcome']}>
            {icon && <Icon.Adaptive size={54} title={''} theme={IconTheme.accent} iconName={icon} className={style.avatar} />}
            {!icon && <Icon.Svg borderSize={2} size={54} title={''} iconName={defaultPersonaIcon} className={style.avatar} fill='red' borderColor='red' />}
            <span className={style.title}>{persona.title}</span>
            <label className={getClassNames(['df-helper-text'])}>{persona?.description}</label>
        </div>
    );
};

export default ChatWelcome;