// Custom
export { ReactComponent as User } from '../../../shared/assets/icons/user.svg';
export { ReactComponent as Leaf } from '../../../shared/assets/icons/dcs-pim-persona.svg';
export { ReactComponent as Bot } from '../../../shared/assets/icons/bot-default.svg';
export { ReactComponent as Bolt } from '../../../shared/assets/icons/bolt.svg';
export { ReactComponent as InstantMix } from '../../../shared/assets/icons/instant_mix.svg';
export { ReactComponent as Robot } from '../../../shared/assets/icons/robot.svg';
export { ReactComponent as Storefront } from '../../../shared/assets/icons/storefront.svg';
export { ReactComponent as Procurement } from '../../../shared/assets/icons/storefront.svg';
export { ReactComponent as Monitoring } from '../../../shared/assets/icons/monitoring.svg';
export { ReactComponent as NetworkIntelNode } from '../../../shared/assets/icons/network-intel-node.svg';

export enum SvgIcons {
    User = 'User',
    PimPersona = 'Leaf',
    Bot = 'Bot',
    Bolt = 'Bolt',
    InstantMix = 'InstantMix',
    Robot = 'Robot',
    Storefront = 'Storefront',
    NetworkIntelNode = 'NetworkIntelNode',
    Monitoring = 'Monitoring',
}

