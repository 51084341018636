import InputLabel from '../input-label/InputLabel';
import Input from '../input/Input';
import Alert from '../alert/Alert';
import usePagination from '../../hooks/usePagination';
import useSkeleton from './useSkeleton';
import { TableColumnType, TableDataType } from '@danfoss/mosaic-core';
import { DfTable } from '@danfoss/mosaic-react';
import { EXCLUDED_TARGET_TABLE_CLASS_NAME } from '../markdown-wrapper/hooks/useTableSorting/useTableSorting';
import { useMemo, useState } from 'react';
import { searchByString } from '../../helpers/listsHelpers';
import { getClassNames } from '../../helpers/classHelpers';
import styles from './Table.module.scss';

type TableProps = {
    className?: string;
    dynamicFilter?: React.ReactNode;
    title: string;
    columns: (TableColumnType & { isSearchable?: boolean; })[];
    data?: TableDataType[];
    labels: {
        placeholder: string;
        notFoundMessage: string;
    };

    pagination?: {
        total?: number;
        onBack?: (page: number) => Promise<void>;
        onNext?: (page: number) => Promise<void>;
    };

    showSkeletonWhileLoading?: boolean;
};

const Table = ({ className, dynamicFilter, labels, title, data, columns, pagination, showSkeletonWhileLoading }: TableProps) => {
    const [search, setSearch] = useState('');
    const hasSearch = useMemo(() => columns.filter(({ isSearchable }) => isSearchable), [columns]);
    const rows = useMemo(() => searchByString(data ?? [], search, true, hasSearch.map(({ id }) => id)), [hasSearch, data, search]);

    const { currentPageChunk, renderPagination } = usePagination({ data: rows, ...pagination });
    const { isInitialSkeleton, isShowingSkeleton, data: skeletonOrData } = useSkeleton({ active: showSkeletonWhileLoading, data: currentPageChunk.length || search.length ? currentPageChunk : undefined, columns: columns.map(({ id }) => id), maxRowNumber: currentPageChunk.length });

    if (!columns.length && !data?.length) return null;

    return (
        <div className={className}>
            <InputLabel className={styles.label} label={title} />
            {!!hasSearch.length && <Input className={styles.search} value={search} placeholder={labels.placeholder} onChange={setSearch} />}

            {dynamicFilter}

            <DfTable
                className={getClassNames([styles.table, isShowingSkeleton && styles.skeleton, isInitialSkeleton && styles['initial-skeleton']])}
                tableProps={{ className: `df-table df-table-flex ${EXCLUDED_TARGET_TABLE_CLASS_NAME}` }}
                columns={columns}
                data={skeletonOrData}
            />

            {((!isShowingSkeleton && data !== undefined && !currentPageChunk?.length)) && <Alert className={styles['not-found']} children={labels.notFoundMessage} />}
            {(!isShowingSkeleton && data !== undefined && !!currentPageChunk?.length) && renderPagination()}
        </div>
    );
};

export default Table;