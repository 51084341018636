import Button, { ButtonThemes } from '../../ui/button/Button';
import Alert from '../../ui/alert/Alert';
import Input from '../../ui/input/Input';
import useLabels from '../../hooks/useLabels';
import Card from '../../ui/card/Card';
import useInfo from '../../hooks/useInfo';
import ChatWelcome from '../chat-welcome/ChatWelcome';
import useStateRef from '../../hooks/useStateRef';
import WindowHeader from '../window-header/WindowHeader';
import hederStyle from '../window-header/WindowHeader.module.scss';
import { getClassNames } from '../../helpers/classHelpers';
import { FC, Fragment, ReactNode, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Icon } from '../../ui/icon/Icon';
import { Icons } from '../../ui/icon/icons/material';
import { IconStyles } from '../../ui/icon/Icon.types';
import { searchBy, sortItemsWith } from '../../helpers/listsHelpers';
import { TestIds } from '../../mocks/ids';
import { ChatInputModel } from '../chat-input';
import { useChat } from '../../contexts/chat/ChatContext';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../router/Routing';
import { ChatViewLayoutProps } from '../../layouts/chat-view-layout/ChatViewLayout';
import { useAdaptive } from '../../contexts/adaptive/AdaptiveContext';
import { ALWAYS_PINED_PERSONAS } from '../../contexts/adaptive/useCatalogFavorites';
import { TourCatalogTargets } from '../../features/tours/hooks/useTourCatalog';
import { PersonaOption } from '../../models/types';
import styles from './PersonasCatalog.module.scss';
import { defaultPersonaIcon } from '../../constants/consts';



const MAX_VISIBLE_PERSONAS = 6;

const PersonasCatalog: FC<ChatViewLayoutProps> = ({ newPromptOrigin, onOpenSidebar }) => {
    const l = useLabels();
    const navigate = useNavigate();
    const { allowedPersonaOptions: personaOptions } = useInfo();
    const { catalogFavorites } = useAdaptive();
    const { persistent: { getInput, mergeInput, lastInputState } } = useChat();

    const [search, setSearch] = useState<string>('');
    const [showAllPersonas, setShowAllPersonas] = useState(false);


    // ----------------------------
    const offsetTopRef = useRef<number>();
    const searchContainerRef = useRef<HTMLDivElement>(null);
    const [headerTitleVisible, setHeaderTitleVisible, headlineTitleVisibleRef] = useStateRef<boolean>();

    useLayoutEffect(() => {
        if (!searchContainerRef.current) return;
        offsetTopRef.current = searchContainerRef.current?.offsetTop;
    }, []);

    const handleScroll = () => {
        if (!searchContainerRef.current) return;
        const offsetTop = searchContainerRef.current.offsetTop;

        if (offsetTop === offsetTopRef.current && !headlineTitleVisibleRef.current) {
            setHeaderTitleVisible(false);
            headlineTitleVisibleRef.current = true;
        }
        else if (offsetTop !== offsetTopRef.current && headlineTitleVisibleRef.current) {
            setHeaderTitleVisible(true);
            headlineTitleVisibleRef.current = false;
        }
    };
    // ----------------------------

    const handleModelMerge = useCallback((model: Partial<ChatInputModel>) => newPromptOrigin && mergeInput(newPromptOrigin, model), [mergeInput, newPromptOrigin]);
    const getPersonaKey = useCallback(() => lastInputState?.persona ?? (newPromptOrigin && getInput(newPromptOrigin)?.persona), [getInput, lastInputState?.persona, newPromptOrigin]);

    const labels = useMemo(() => ({
        alpha: l.alpha,
        beta: l.beta,
        headline: l.personasCatalogHeadline,
        description: l.personasCatalogDescription,
        searchPlaceholder: l.personasCatalogSearchPlaceholder,
        noResultsFoundOnSearch: l.personasCatalogNoResults,

        catPopular: l.personasCatalogPopular,
        reset: l.personasReset,
        catAll: l.personasCatalogAll,
        seeAll: l.seeMore,
        seeLess: l.seeLess,

        catalogTitle: l.catalogTitle,
        catalogDescription: l.personasCatalogDescription,
        personasCatalogError: l.personasCatalogError,
    }), [l]);

    const container = useCallback((headline: string, children: ReactNode, extraHeadline?: ReactNode, id?: string) => {
        return <div className={styles.container} id={id}>
            <b>{headline} {extraHeadline}</b>
            <div className={styles['container-children']}>
                {children}
            </div>
        </div>;
    }, []);

    const onClickCard = useCallback((persona: string, route?: string) => {
        handleModelMerge({ persona });
        navigate(route ? "/" + route : AppRoute.chat);
    }, [handleModelMerge, navigate]);

    const renderCard = useCallback((limit?: number, preventPinning = false, attachTourId?: boolean) => (persona: PersonaOption, index: number) => {
        if (!!limit && index + 1 > limit && !showAllPersonas) return null;

        const { description, key, title, route, avatar, versions } = persona;
        const $attachTourId = attachTourId && index === 0;
        const revision = versions?.[0].revisions?.[0];
        return <Fragment key={key}>
            <Card
                id={$attachTourId ? TourCatalogTargets.select : undefined}
                key={key}
                className={getClassNames([styles.card, revision?.alpha && styles.alpha, revision?.beta && styles.beta])}
                dataAttributes={{ 'data-content': revision?.alpha ? labels.alpha : labels.beta }}
                icon={{ iconName: avatar ?? defaultPersonaIcon }}
                title={title}
                description={description}
                isActive={getPersonaKey() === key}
                onClick={onClickCard.bind(this, key, route)}
                rightSide={(!ALWAYS_PINED_PERSONAS.includes(key) && !preventPinning) && <Icon.Async
                    id={index === 0 ? TourCatalogTargets.star : undefined}
                    iconName={catalogFavorites.personas_pinned.includes(key) ? Icons.star : Icons.starOutline}
                    iconStyle={IconStyles.outlined}
                    onAsyncClick={async (e) => {
                        e.stopPropagation();
                        await catalogFavorites.toggleFavorite(key);
                    }}
                    title=''
                />}
            />
        </Fragment>;
    }, [labels, showAllPersonas, getPersonaKey, onClickCard, catalogFavorites]);


    const personas = useMemo(() => personaOptions?.map((item) => ({
        ...item,
        id: item.key,
        onClick: () => navigate(AppRoute.chat)
    })), [navigate, personaOptions]);

    const searchedPersonas = useMemo(() => searchBy(personas, search, ['title', 'description']), [personas, search]);

    const favoriteList = useMemo(() => sortItemsWith(searchedPersonas, item => ALWAYS_PINED_PERSONAS.includes(item.key), true)?.filter(({ key }) => catalogFavorites.personas_pinned?.includes(key)).map(renderCard(undefined, catalogFavorites.disallowPinning, true)), [searchedPersonas, renderCard, catalogFavorites.disallowPinning, catalogFavorites.personas_pinned]);
    const personasList = useMemo(() => searchedPersonas?.filter(({ key }) => !catalogFavorites.personas_pinned?.includes(key))?.map(renderCard(MAX_VISIBLE_PERSONAS, undefined, !favoriteList.length)), [searchedPersonas, renderCard, favoriteList, catalogFavorites.personas_pinned]);

    const personasListExtras = useMemo(() => {
        return <div className={styles['personas-see-all']}>
            ({personasList.length})
            {personasList.length > MAX_VISIBLE_PERSONAS && <Button label={showAllPersonas ? labels.seeLess : labels.seeAll} theme={ButtonThemes.textPrimary} onClick={() => setShowAllPersonas(s => !s)} />}
        </div>;
    }, [personasList.length, labels.seeAll, labels.seeLess, showAllPersonas]);

    const left = !!onOpenSidebar && <Icon.Base title={l.menuIconTitle} className={getClassNames([hederStyle.icon])} iconName={Icons.menu} onClick={onOpenSidebar} />;

    const renderSearch = <div className={styles.search}>
        <Input id={TourCatalogTargets.search} placeholder={labels.searchPlaceholder} value={search} onChange={setSearch} />
    </div>;

    return (
        <>
            <WindowHeader
                className={getClassNames([styles['header-title'], !headerTitleVisible && styles.visible,])}
                left={left}
                center={renderSearch}
            />
            <div className={styles.catalog} data-testid={TestIds.catalogView} onScroll={handleScroll} >
                <div className={styles.welcome}>
                    <ChatWelcome avatar={Icons.appRegistration} persona={{ title: labels.headline, description: labels.description, key: '', route: "", type: "chat", versions: [] }} />
                </div>
                <div className={styles.content}>
                    {/* <div className={styles.header} ref={searchContainerRef}>
                        {renderSearch}
                    </div> */}

                    {!favoriteList.length && !personasList.length && !search
                        ? <Alert className={styles.notification} description={labels.personasCatalogError} />
                        : <div className={styles.body}>
                            {!!favoriteList.length && container(labels.catPopular, favoriteList, undefined, TourCatalogTargets.personaList)}
                            {!!personasList.length && container(labels.catAll, personasList, personasListExtras, !favoriteList.length ? TourCatalogTargets.personaList : undefined)}
                            {!!search && !favoriteList.length && !personasList.length && <Alert className={styles.notification} description={labels.noResultsFoundOnSearch} />}
                        </div>}
                </div>
            </div>
        </>
    );
};

export default PersonasCatalog;