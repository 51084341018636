
import { ChatMessageErrorCode } from "../helpers/messageStreamHelper";
import { ErrorLabels } from "../models/types";

const labels = {
    // Generic
    cancel: 'Cancel',
    close: 'Close',
    delete: 'Delete',
    rename: 'Rename',
    submit: 'Submit',
    goBack: 'Go back',
    retry: 'Retry',
    update: 'Update',
    next: 'Next',

    seeMore: 'See more',
    seeLess: 'See less',

    draft: 'Draft',
    save: 'Save',

    // Personas catalog
    personasCatalogHeadline: 'Assistant Catalog',
    personasCatalogDescription: 'Find different assistant from our extensive catalog, according to your search needs.',
    personasCatalogError: 'Oops something went wrong loading the catalog. Please try again later.',
    personasCatalogSearchPlaceholder: 'Search',
    personasCatalogPopular: 'Popular',
    personasReset: 'Reset to default',
    personasCatalogAll: 'All personas',
    personasCatalogNoResults: 'No results found on your search criteria',
    personasCatalogExplore: ' Explore Assistant Catalog',
    // 
    catalogHeadline: 'My assistant',

    // Landing page
    welcome: 'Welcome to the Danfoss AI Chatbot',
    welcomeDescription: 'Welcome to Danfoss AI Chatbot, the intelligent AI chatbot experience. This innovative tool can assist you in generating ideas, brainstorming concepts, and crafting compelling content, all while streamlining your daily workflow.',
    login: 'Login',

    // Auth Error pages
    // AuthenticationState related
    notAuthorizedYetAlertDescription: `We apologize for the inconvenience!
    Please try logging out and then logging back in to see if it helps.
    Please also be aware that we have a pending approval with the German work councils.
    For further troubleshooting, please reach out to the "Data Analytics Community" on Microsoft Teams.`,
    // `We are currently testing the solution with a closed test audience. 
    // We do not currently accept new access requests.
    // You will be notified when this solution is open to a wider audience.`,

    // Relevant just in case we need individual error messages
    notAuthorizedYetAlertHeadline: `We are sorry! Unfortunately, you are not authorized to use this application.`,
    notAuthorizedDescription: 'We are sorry! Unfortunately, you are not authorized to use this application.',
    accessBlockedAlertHeadline: 'We are sorry! Unfortunately, you are not authorized to use this application.',

    chatNotFound: 'Unfortunately, we cannot find this conversation.',


    unknownErrorHeadline: 'An unknown error has occurred',
    unknownErrorDescription: 'We are sorry! Unfortunately, an unknown error occurred. Please try again later or contact us if the issue persists.',

    // TokenState related
    tokenExpiredHeadline: 'Session expired',
    tokenExpiredDescription: 'Please log in again to continue using the application.',

    invalidTokenHeadline: 'Invalid token',
    invalidTokenDescription: 'We are sorry! Unfortunately, the token is invalid. Please try again later or contact us if the issue persists.',

    loadingErrorDescription: 'Please try again later or contact us if the issue persists.',

    genericErrorHeadline: 'An error occurred',

    // Navigation
    changeLanguage: 'Change language',
    logout: 'Logout',


    // Chat
    newChatTitle: 'New chat',
    // chatWelcome: 'How can I assist you?',
    inputPlaceholder: 'Type a message...',
    userInstructions: 'User Instructions',
    disclaimerHeadline: 'Chat Guidelines',

    termsApiError: 'Unable to display terms at the moment',
    disclaimerConfirmationMarkdown: 'By using our Danfoss AI chatbot, you agree to have read and understood this User Instruction and the [Danfoss data privacy policy](https://www.danfoss.com/en/terms/privacy/)',
    disclaimerButton: 'Got it',
    disclaimerNote: 'Note: Answers made by the Danfoss AI Chatbot are computer-generated and may be inaccurate, incomplete, or outdated, and does not reflect the opinion of Danfoss. Use with care.',
    productivityHeadline: 'Productivity gain',
    productivityDescription: 'Submit productivity gain (minutes)',
    productivityPlaceholder: 'Enter minutes',

    // Chat - Context sources
    sources: 'Sources',
    miscSources: 'Misc.',
    goToSource: 'Go to source',
    viewMore: 'View more',
    viewLess: 'View less',

    // Chat Enquiry
    enquiryHeadline: 'Enquiry analysis',
    enquiryQuestion: 'Question',
    enquiryCategory: 'Category',
    enquiryKeywords: 'Keywords',

    // Chat - Actions
    comment: 'Comment',
    commentHeadline: 'Submit message feedback',
    commentDescription: 'The feedback are anonymous and will be used to improve the chatbot.',
    likeTitle: 'Like response',
    dislikeTitle: 'Dislike response',
    copyTitle: 'Copy response',
    messageInfoTitle: 'Information about response',
    historyErrorHeadline: 'Error',
    historyErrorOccurredWhileLoading: 'An error occurred while loading the conversation',


    // Sidebar
    newChat: 'Start new chat',
    chatHistory: 'Chat history',
    clearHistoryHeadline: 'Delete all chats?',
    clearHistoryDescription: 'By clearing your chat history, you will not be able to see previous conversations. This action cannot be reverted. Are you sure you want to continue?',
    clearHistory: 'Clear history',
    deleteChatHeadline: 'Delete chat?',
    deleteChatDescription: 'By deleting this chat, you will not be able to see previous messages within this chat. This action cannot be reverted. Are you sure you want to continue?',
    deleteIconTitle: 'Delete conversation',


    // Backend messages AKA System events
    chatNameUpdated: 'Chat renamed to:',
    documentAdded: 'Document added',
    documentRemoved: 'Document removed',
    documentAddedCta: 'See documents',

    // Error handling: 
    // - Prompt submit errors
    submitFailed: "Unable to submit message",
    offlineError: "Check your internet connectivity.",
    // - Markdown render error
    markdownRenderError: "An error occurred while parsing this content",

    // useActions 
    // data - side panel
    noDynamicData: 'No data is available',

    // Documents
    docListDownload: 'Download',
    docListDelete: 'Delete',
    docListOpen: 'Open',
    docListEdit: 'Edit',
    docListCopy: 'Copy',
    docListRefresh: 'Refresh documents list',

    // docReadyState: 'Ready', //
    // docProcessingState: 'Processing..', //
    // docErrorState: 'Failed', //

    docInfoHeader: 'Information and limitations',


    // Dates
    today: 'Today',
    yesterday: 'Yesterday',
    within7Days: 'Previous 7 days',
    within30Days: 'Previous 30 days',
    jan: 'January',
    feb: 'February',
    mar: 'March',
    apr: 'April',
    may: 'May',
    jun: 'June',
    jul: 'July',
    aug: 'August',
    sep: 'September',
    oct: 'October',
    nov: 'November',
    dec: 'December',


    // Advanced/Settings
    advancedSettings: 'Advanced settings',
    temperature: 'Temperature',
    model: 'Model',
    version: 'Version',
    modelIconTitle: 'Information about available models',
    modelDescriptions: 'Model descriptions',
    temperatureMin: 'Precise',
    temperatureMid: 'Balanced',
    temperatureMax: 'Creative',

    // Personas
    alpha: 'Alpha',
    beta: 'Beta',
    persona: 'Assistant',
    choosePersona: 'Choose an assistant',
    choosePersonaIconTitle: 'Information about available assistant',
    choosePersonaDescriptions: 'Choose the assistant with specific knowledge to assist you with your chat. The assistant will provide you with different responses based on your choice of scope for your chat.',
    invalidPersona: 'The selected assistant, assistant version or model is no longer available',

    unknownAssistantTitle: 'Unknown',
    advancedAssistantTitle: 'Advanced Chat Assistant',
    advancedTitle: 'Advanced Chat Assistant',
    advancedDescription: 'Customize LLM options to fit your use case (the available LLMs are generic/non-Danfoss specific)',

    catalogTitle: 'Assistant Catalog',
    catalogDescription: 'Find different assistant from our extensive catalog, according to your search needs.',

    // TBD
    tbd: (prefix = '') => `${prefix}${prefix ? ' feature' : 'Feature'} is in progress.`,


    // Table search
    tableSearchHeadline: 'Search within the table',
    tableSearchNotResultsFound: 'No results found for the search criteria',

    // Sidebar actions
    viewDetails: 'View details',
    productDetails: 'Product details',
    noResultsFound: (id: string) => `No results found for: ${id}`,
    embeddedToolHeadline: 'Embedded tool',
    chainViewerHeadline: 'Insights',
    pdfViewer: 'PDF viewer',
    pdfCannotBeDisplayed: 'PDF cannot be displayed',
    pdfIncorrectLink: 'Incorrect link to the PDF file',
    pdfError: 'Unknown error occurred while loading the PDF file',

    // Insights
    input: 'Input',
    output: 'Output',
    artifacts: 'Artifacts',
    showMoreDetails: 'See more details',
    displayTechnicalDetails: 'Technical details',

    // ChatWindowHeader 
    reportGainIconTitle: 'Report the time gain',
    createNewChatIconTitle: 'Create a new conversation',
    editChatNameIconTitle: 'Edit conversation name',
    menuIconTitle: 'Side menu',
    closeSidebarIconTitle: 'Close side panel',
    openInNewTabTitle: 'Open in the new tab',
    navigateToInformationViewIconTitle: 'Navigate to the information view',

    // ChatInput
    submitTitle: 'Submit an inquiry',
    stopSubmitTitle: 'Stop inquiry generation',

    // ChatMessage
    enquiryAnalysisTitle: 'Enquiry analysis',

    // Information page
    informationPageCta: 'Information',
    viewReleaseNotes: 'View details',


    // Feedback
    feedbackHeadline: 'Feedback / Suggestions',
    feedbackDescription: 'Thank you for using Danfoss AI Chatbot, we are looking forward to your feedback.',
    feedbackDisclaimer1: 'Please note, for submitting feedback regarding the AI chatbot responses, use the feedback buttons',
    feedbackDisclaimer2: 'that are located beneath the response itself',
    feedbackButton: 'Report/Suggest',

    feedbackAddress: 'Feedback - Danfoss Global GPT <c7eebb39.danfoss.onmicrosoft.com@emea.teams.ms>',
    feedbackSubject: 'Feedback for chat.danfoss.com',
    feedbackBody: (user: string) => {
        return [
            "Hi",
            `My name is: ${user}`,
            "I would like to report an issue / suggest an improvement on chat.danfoss.com.",
            "Details:",
            ""
        ].join("\n");
    },

    // Info About
    aboutHeadline: 'About Danfoss AI Chatbot',
    aboutDescription: 'The Danfoss AI Chatbot is a tool for employees to quickly access information and assistance. It combines publicly available knowledge with internal Danfoss information to answer your questions.',
    aboutNoteHeadline: "While the chatbot strives for accuracy, it's important to note:",
    aboutNote1a: 'Some information may not be up-to-date or complete.',
    aboutNote1b: 'Always verify critical information.',
    aboutNote2: 'The chatbot does not represent official Danfoss opinions.',
    aboutNote3a: 'Respect copyright.',
    aboutNote3b: 'Any third-party links or information provided may have usage restrictions.',
    aboutOutro: 'Use the chatbot responsibly, in line with Danfoss IT policies. Misuse may result in access termination.',

    //AiAssistantChanges
    aiAssistantHeadline: 'AI Assistant Release Notes',
    // UiUxChanges
    uiUxHeadline: 'UI/UX Release Notes',
    // ReleaseNotesOverview
    releaseNotesHighlights: 'Highlight of Recent Changes',
    releaseNotesUiUx: 'User Interface/User experience',

    // ReleaseNotesOverview
    releaseNotesHeadline: 'Release Notes',
    releaseNotesAssistantHeadline: 'AI Assistants / Backend',
    releaseNotesVersions: 'Current release version:',

    // Image 
    imageEnlargeCta: 'Enlarge image',
    imageEnlargeAlt: 'Enlarged view',

    // Document Upload
    uploadDocumentTitle: 'Document upload',
    fetchErrorHeadline: 'Fetch Error',
    fetchErrorMessage: '"Error fetching documents."',
    uploadLimitErrorHeadline: 'File upload limit reached',
    uploadLimitErrorMessage: (limit?: number) => `You have exceeded ${limit} file upload limit. Please delete some documents before uploading new ones.`,
    // uploadErrorHeadline: 'Upload error', //
    // uploadErrorMessage: 'Error uploading document(s):', //
    // deleteErrorHeadline: 'Delete error',//
    // deleteErrorMessage: 'Error deleting documents.',//
    // updateErrorHeadline: 'Update error',//
    // updateErrorMessage: 'Error updating document',//
    // downloadErrorHeadline: 'Download error',//
    // downloadErrorMessage: 'Error downloading documents.',//
    exceededDocSizeError: (size: string) => `Files must be less than ${size}.`,
    copyToNewChatHeadline: 'Copy to a new chat',
    copyToNewChatDescription: 'The following documents will be copied to a new chat:',
    copyToNewChatCta: 'Copy',
    copyToNewChatCancelCta: 'Cancel',
    copySelectChatAssistant: 'Select chat assistant',
    copySelectChatAssistantVersion: 'Select chat assistant version',
    dropFilesAreaUploadText: 'Drop files to upload or',
    dropFilesAreaBrowseText: 'Browse',
    deleteDocumentModalTitle: 'Delete Document?',
    deleteDocumentConfirmationText: 'Are you sure you want to delete following document(s):',
    deleteDocumentDeleteButton: 'Delete',
    deleteDocumentCancelButton: 'Cancel',
    documentUpdateHeader: 'Edit Document',
    invalidDocumentTypeMessage: (allowedExtensions?: string[]) => `Unsupported file format, please use files with the following formats: ${allowedExtensions?.join(", ")}`,
    invalidDocumentTypeHeader: 'Invalid document format.',
    namePlaceholder: 'Enter Name',
    descriptionPlaceholder: 'Enter Description',
    invalidFileNameError: 'Invalid file name',
    invalidFileNameLength: 'Exceeded allowed filename length',
    invalidFileNameChars: 'Unsupported input character(s)',
    noLocalDocumentsMessage: 'No documents found in local storage.',
    downloadErrorUUID: 'Failed to download document with DocumentId:',
    duplicateDocumentError: 'Each file must have a unique name. The following files already exist in the list:',
    duplicateNameErrorHeadline: 'Duplicate file name',

    // copyErrorHeadline: 'Error copying documents',//
    copyErrorValidateMaxSize: (amount: number) => `The maximum number of selected documents has been exceeded. This assistant can handle only up to ${amount} document(s).`,
    copyErrorValidateMaxLimit: (size: string, files: string) => `The following files exceed the maximum allowed size of ${size} for the selected Assistant: ${files}`,
    copyErrorValidateExtension: (files: string) => `The selected Assistant does not support the following selected files: ${files}`,

    // Documents list
    addDescription: 'Add description',

    // Anchor to headline
    anchorHeadlineTitle: 'Copy the link to the resource',

    // DcsPromo
    promHi: `Hi`,
    promoPlaceholderName1: 'Dear',
    promoPlaceholderName2: 'User',

    togglePlayPromo: 'Play / Pause',
    toggleMutePromo: 'Mute / Unmute',


    // Admin 
    adminAddNew: 'Add new entry',
    adminExamplePlaceholder: 'Enter example',
};

export const fileUploadErrors = {
    addDescriptionCta: 'Add description',
    seeMore: 'Show more',
    seeLess: 'Show less',

    editError: {
        headline: 'Update error',
        message: 'Error updating document'
    },
    updateError: {
        headline: 'Upload error',
        message: 'Error uploading document(s):'
    },
    downloadError: {
        headline: 'Download error',
        message: 'Error downloading document(s).'
    },
    deleteError: {
        headline: 'Delete error',
        message: 'Error deleting document(s).'
    },
    copyError: {
        headline: 'Error copying documents',
    },
    chipStates: {
        ready: 'Ready',
        error: 'Failed',
        processing: 'Processing..'
    },
};


export const inputValidationLabels = {
    messageTooLong: 'Character limit reached',
};

export const sidePanelErrors = {
    'view-depth-limit-exceeded': {
        headline: 'Maximum view depth reached',
        description: 'You have reached the maximum view depth. Please try close some of the previous side panels.',
    }
} as Record<string, { headline: string, description: string; }>;

export const chatMessageErrorLabels: ErrorLabels<ChatMessageErrorCode> = {
    [ChatMessageErrorCode.Unknown]: {
        h: "Something went wrong",
        d: "Please try again later or contact us if the issue persists."
    },
    [ChatMessageErrorCode.Cancelled]: {
        h: "Response generation cancelled",
        d: "The response generation was cancelled. Please try again if this was a mistake."
    },
    [ChatMessageErrorCode.ContentFilterViolation]: {
        h: "Content violation",
        d: "The message was flagged for content violation. If you believe this is a mistake, please use the feedback functionality to report it."
    },
    [ChatMessageErrorCode.TokenLimitExceeded]: {
        h: "Token limit is reached",
        d: "This model's maximum token limit is reached for the session. Please use 'Start new chat' to continue."
    }
};


export const countries = {
    VU: "Vanuatu",
    EC: "Ecuador",
    VN: "Vietnam",
    VI: "Amer.virgin island",
    DZ: "Algeria",
    VG: "Brit.Virgin Island",
    DM: "Dominica",
    VE: "Venezuela",
    DO: "Dominican Republic",
    VC: "St. Vincent",
    VA: "Vatican city",
    DE: "Germany",
    UZ: "Uzbekistan",
    UY: "Uruguay",
    DK: "Denmark",
    DJ: "Djibouti",
    US: "USA",
    UM: "US Minor Outlying Islands",
    EU: "European Union",
    UG: "Uganda",
    UA: "Ukraine",
    ET: "Ethiopia",
    ES: "Spain",
    ER: "Eritrea",
    EG: "Egypt",
    TZ: "Tanzania",
    EE: "Estonia",
    TT: "Trinidad and Tobago",
    TW: "Taiwan R.O.C.",
    TV: "Tuvalu",
    GD: "Grenada",
    GE: "Georgia",
    GF: "French Guinea",
    GA: "Gabon",
    GB: "Great Britain",
    XK: "Kosovo",
    N: "All countries",
    FR: "France",
    FO: "Faroe Islands",
    FK: "Falkland islands",
    FJ: "Fiji",
    FM: "Micronesia",
    FI: "Finland",
    WS: "Western Samoa",
    GY: "Guyana",
    GW: "Guinea-Bissau",
    GU: "Guam",
    GT: "Guatemala",
    GR: "Greece",
    GQ: "Equatorial Guinea",
    WF: "Wallis,futuna",
    GP: "Guadeloupe",
    GN: "Guinea",
    GM: "Gambia",
    GL: "Greenland",
    GI: "Gibraltar",
    GH: "Ghana",
    RE: "Reunion",
    RO: "Romania",
    AT: "Austria",
    AS: "Samoa, American",
    AR: "Argentina",
    AQ: "Antarctica",
    QA: "Qatar",
    AW: "Aruba",
    AU: "Australia",
    AZ: "Azerbaijan",
    BA: "Bosnia-Herzegovina",
    PT: "Portugal",
    AD: "Andorra",
    PW: "Palau",
    AG: "Antigua/Barbuda",
    PR: "Puerto Rico",
    AE: "United Arab Emirates",
    AF: "Afghanistan",
    AL: "Albania",
    STL: "stateless",
    AI: "Anguilla",
    AO: "Angola",
    PY: "Paraguay",
    AM: "Armenia",
    AN: "Dutch Antilles",
    BW: "Botswana",
    TG: "Togo",
    BY: "Belarus",
    TD: "Chad",
    BS: "Bahamas",
    BR: "Brazil",
    TJ: "Tadzhikistan",
    BT: "Bhutan",
    TH: "Thailand",
    TO: "Tonga",
    TN: "Tunesia",
    TM: "Turkmenistan",
    CA: "Canada",
    BZ: "Belize",
    TR: "Turkey",
    BF: "Burkina Faso",
    BG: "Bulgaria",
    SV: "El Salvador",
    BH: "Bahrain",
    BI: "Burundi",
    ST: "Sao Tome and Principe",
    BB: "Barbados",
    SY: "Syria",
    SZ: "Swaziland",
    BD: "Bangladesh",
    BE: "Belgium",
    BN: "Brunei Daruessalam",
    BO: "Bolivia",
    BJ: "Benin",
    TC: "Turksh Caicosin",
    BM: "Bermuda",
    SD: "Sudan",
    CZ: "Czech Republic",
    SC: "Seychelles",
    CY: "Cyprus",
    CX: "Christmas Island",
    SE: "Sweden",
    SH: "St. Helena",
    CV: "Cape Verdian",
    SG: "Singapore",
    CU: "Cuba",
    SI: "Slovenia",
    SL: "Sierra Leone",
    SK: "Slovakia",
    SN: "Senegal",
    SM: "San marino",
    SO: "Somalia",
    SR: "Suriname",
    CI: "Ivory coast",
    RS: "Serbia",
    CG: "Congo",
    RU: "Russian Federation",
    CH: "Switzerland",
    RW: "Rwanda",
    CF: "Central African Republic",
    CC: "Cocos Islands",
    CD: "Democratic Republic of Congo",
    CR: "Costa Rica",
    CO: "Colombia",
    CM: "Cameroon",
    CN: "China",
    SA: "Saudi Arabia",
    CK: "Cook Islands",
    SB: "Solomon Islands",
    CL: "Chile",
    LV: "Latvia",
    LU: "Luxembourg",
    LT: "Lithuania",
    LY: "Libya",
    LS: "Lesotho",
    LR: "Liberia",
    MG: "Madagascar",
    MH: "Marshall Islands",
    ME: "Montenegro",
    MK: "Macedonia",
    ML: "Mali",
    MC: "Monaco",
    MD: "Moldavia",
    MA: "Morocco",
    MV: "Maldives",
    MU: "Mauritius",
    MX: "Mexico",
    MW: "Malawi",
    MZ: "Mozambique",
    MY: "Malaysia",
    MN: "Mongolia",
    MM: "Myanmar",
    MP: "Northern Mariana Islands",
    MO: "Macau",
    MR: "Mauretania",
    MQ: "Martinique",
    MT: "Malta",
    MS: "Montserrat",
    NF: "Norfolk Island",
    NG: "Nigeria",
    NI: "Nicaragua",
    NL: "Netherlands",
    NA: "Namibia",
    NC: "New Caledonia",
    NE: "Niger",
    XDK: "Denmark Exports",
    NZ: "New Zealand",
    NU: "Niue Islands",
    NR: "Nauru",
    NP: "Nepal",
    NO: "Norway",
    OM: "Oman",
    PL: "Poland",
    PM: "St. Pierre and Miquelon",
    PH: "Philippines",
    PK: "Pakistan",
    PE: "Peru",
    PF: "French Polynesia",
    PG: "Papua New Guinea",
    PA: "Panama",
    HK: "Hong Kong",
    ZA: "South Africa",
    HN: "Honduras",
    HR: "Croatia",
    HT: "Haiti",
    HU: "Hungary",
    ZM: "Zambia",
    ID: "Indonesia",
    ZW: "Zimbabwe",
    ZR: "Zaire",
    IE: "Ireland",
    IL: "Israel",
    IM: "Isle of Man",
    IN: "India",
    IQ: "Iraq",
    IR: "Iran",
    YE: "Yemen",
    IS: "Iceland",
    IT: "Italy",
    YT: "Mayotte",
    JP: "Japan",
    JO: "Jordan",
    JM: "Jamaica",
    KI: "Kiribati",
    KH: "Cambodia",
    KG: "Kirghistan",
    KE: "Kenya",
    KP: "North Korea",
    KR: "Korea",
    KM: "Comorin",
    KN: "St. Kitts and Nevis",
    KW: "Kuwait",
    KY: "Cayman Islands",
    KZ: "Kazakhstan",
    LA: "Laos",
    LC: "St. Lucia",
    LB: "Lebanon",
    LI: "Liechtenstein",
    LK: "Sri Lanka"
};

export const languages = {
    hr: "Croatian",
    zh: "Chinese",
    ro: "Romanian",
    vi: "Vietnamese",
    tr: "Turkish",
    no: "Norwegian",
    hu: "Hungarian",
    lv: "Latvian",
    hi: "Hindi",
    lt: "Lithuanian",
    ga: "Irish",
    th: "Thai",
    de: "German",
    id: "Indonesian",
    fi: "Finnish",
    ta: "Tamil",
    bg: "Bulgarian",
    fr: "French",
    sv: "Swedish",
    mk: "Macedonian",
    sl: "Slovenian",
    sk: "Slovak",
    uk: "Ukrainian",
    is: "Icelandic",
    da: "Danish",
    it: "Italian",
    sr: "Serbian",
    ko: "Korean",
    fa: "Persian",
    ar: "Arabic",
    he: "Hebrew",
    kk: "Kazakh",
    cs: "Czech",
    el: "Greek",
    pt: "Portuguese",
    pl: "Polish",
    en: "English",
    ru: "Russian",
    et: "Estonian",
    es: "Spanish",
    ja: "Japanese",
    nl: "Dutch"
};

export default labels;