import Chip, { Version } from '../chip/Chip';
import ChipList from '../chip-list/ChipList';
import StatusHeader from '../status-header/StatusHeader';
import Button, { ButtonThemes } from '../button/Button';
import Description from '../description/Description';
import useDocumentEvents from '../../elements/hooks/useDocumentEvents';
import ListItems from '../list-items/ListItems';
import { useMemo, FC } from 'react';
import { readableFileSize } from '../../helpers/fileHelpers';
import { formatDate } from '../../helpers/dateHelpers';
import { getFinalDocumentStatus, getStatusLabelType } from '../../helpers/statusHelpers';
import { TestIds } from '../../mocks/ids';
import { FileType, FileUploadStatus } from '../../hooks/useFileUpload';
import { withUIState } from '../../contexts/ui-state/withUIState';
import styles from './FileList.module.scss';


export type FileListProps = {
    chipId?: string;
    isLoading?: boolean;

    labels: {
        chipStates: {
            ready: string;
            error: string;
            processing: string;
        };

        addDescriptionCta: string;
        seeMore: string;
        seeLess: string;
    };

    files: FileType[];
    loadingIds: string[];
    selectedIds: string[];
    isCheckboxDisabled?: boolean;
    isButtonDisabled?: boolean;
    onToggleSelectId?: (id: string) => void;
    onEdit?: ({ name, description, fileId }: { name: string, description: string, fileId: string; }) => void;
};

const FileList: FC<FileListProps> = ({ isLoading, chipId, isCheckboxDisabled, isButtonDisabled, labels, files, selectedIds, loadingIds, onEdit, onToggleSelectId }) => {
    const { events } = useDocumentEvents();

    const filesList = useMemo(() => files.map((document) => {
        const { fileName: name, description, status, size, extension, documentId: id, created } = document || {};

        const base = { id, name, description, status, size, extension, onItemSelect: onToggleSelectId };

        const calculatedStatus = getFinalDocumentStatus(document, events);
        const statusLabelMap = () => {
            switch (calculatedStatus) {
                case FileUploadStatus.Error: return labels.chipStates.error;
                case FileUploadStatus.Processing: return labels.chipStates.processing;
                case FileUploadStatus.Ready: return labels.chipStates.ready;
                default: return 'N/A';
            }
        };

        const footer = (!description && onEdit
            ? <Button
                isDisabled={isButtonDisabled || isLoading}
                className={styles['description-cta']}
                theme={ButtonThemes.textPrimary}
                label={labels.addDescriptionCta}
                onClick={() => onEdit?.({ name, description, fileId: id })}
            />
            : <Description
                threshold={140}
                description={description}
                labels={labels}
            />
        );

        const children = (<div className={styles['chip-wrapper']}>
            <Chip
                id={chipId}
                className={styles.progress}
                label={statusLabelMap()}
                theme={getStatusLabelType(calculatedStatus)}
                version={Version.minified}
            />
            <span className={styles.separator}>|</span>
            <ChipList
                minChipWidth={30}
                chips={[
                    { label: readableFileSize(size) },
                    { label: extension },
                    { label: formatDate?.(created, 'en', true) }
                ]} />
        </div>
        );

        const header = <StatusHeader headline={name} />;

        return { ...base, header, footer, children, };
    }), [files, onToggleSelectId, events, onEdit, isButtonDisabled, isLoading, labels, chipId]);

    if (!filesList.length) return null;

    return (
        <div data-testid={TestIds.documentList} className={styles['file-list']}>
            <ListItems
                isDisabled={isCheckboxDisabled || isLoading}
                data-testid={TestIds.documentListItems}
                childrenClassName={styles.children}
                headerClassName={styles.header}
                itemsSelected={selectedIds}
                loadingItemIds={loadingIds}
                items={filesList}
            />
        </div>
    );
};

export default withUIState(FileList);