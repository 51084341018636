import labels, { fileUploadErrors } from "../../../../../../data/labels";
import Loader from "../../../../../loader/Loader";
import Information from './components/information/Information';
import useInfo from '../../../../../../hooks/useInfo';
import FileInput from '../../../../../file-input/FileInput';
import FileListActions from '../../../../../file-list-actions/FileListActions';
import FileList from '../../../../../file-list/FileList';
import FileListActionsModal from '../../../../../file-list-actions-modal/FileListActionsModal';
import Input from '../../../../../input/Input';
import Textarea from '../../../../../textarea/Textarea';
import useDocumentEvents from '../../../../../../elements/hooks/useDocumentEvents';
import useLabels from '../../../../../../hooks/useLabels';
import { useState, useCallback, useRef, useEffect, useMemo, ReactNode } from "react";
import { useChat } from "../../../../../../contexts/chat/ChatContext";
import { DocumentInfoModel, DocumentRow, DocumentsResponse, DocumentUpdateRequest } from "../../../../../../models/ChatTypes";
import { TestIds } from "../../../../../../mocks/ids";
import { useErrorHandlerContext } from '../../../../../../contexts/error-handler/ErrorContext';
import { getExtension, readableFileSize } from '../../../../../../helpers/fileHelpers';
import { ErrorHandler } from '../../../../../../contexts/error-handler/ErrorHandler';
import { AxiosProgressEvent } from 'axios';
import { useAppInfo } from '../../../../../../contexts/app-info/AppInfoContext';
import { useAdaptive } from '../../../../../../contexts/adaptive/AdaptiveContext';
import { DEMO_TOUR_FILE_UPLOAD_KEY, DEMO_TOUR_SUCCESS_FILE_UPLOAD_KEY, TourFileUploadTargets } from '../../../../../../features/tours/hooks/useTourFileUploads';
import { isString } from '../../../../../../helpers/typeHelpers';
import { SelectStatic } from '../../../../../select/Select';
import { advancedAssistantKey, advancedAssistantRoute, tenderAssistantKey } from '../../../../../../constants/consts';
import { useLocation, useNavigate } from 'react-router-dom';
import { FileUploadStatus, useFileListActions, useFileListState } from '../../../../../../hooks/useFileUpload';
import styles from "./Document.module.scss";

export const Documents = ({ chatId, objectId, showInfo = true, reportUploadStateChange }: { chatId: string; objectId?: string; showInfo?: boolean; reportUploadStateChange?: (data?: DocumentRow[]) => void; }) => {
    const isDemoTour = useMemo(() => [DEMO_TOUR_FILE_UPLOAD_KEY, DEMO_TOUR_SUCCESS_FILE_UPLOAD_KEY].includes(objectId || ''), [objectId]);

    const demoTour: DocumentRow[] = useMemo(() => [
        {
            documentId: 'demo-tour',
            fileName: 'Uploaded document name',
            description: 'This document contains some important information.',
            status: objectId === DEMO_TOUR_SUCCESS_FILE_UPLOAD_KEY ? FileUploadStatus.Ready : FileUploadStatus.Processing,
            size: 1345674,
            extension: 'pdf',
            created: new Date().toISOString()
        }
    ], [objectId]);


    // TODO clean styling 

    const navigate = useNavigate();
    const { hash } = useLocation();
    const { chatInfo } = useAdaptive();
    const { events } = useDocumentEvents();
    const { getPersonaVersion } = useInfo();

    const { documents: globalDocuments } = useAppInfo();
    const { persistent: { getInput, mergeInput }, uploadDocument, fetchDocuments } = useChat();
    const { updateDocumentMetadata, deleteDocuments, downloadDocuments, copyDocuments, persistent: { setInput } } = useChat();
    const { errorId, registerError, removeError } = useErrorHandlerContext();

    const initialLoadRef = useRef(false);
    const [data, setData] = useState<DocumentRow[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [activeModal, setActiveModal] = useState<string | undefined>(undefined);
    const [editState, setEditState] = useState<Pick<DocumentRow, 'fileName' | 'description' | 'documentId'>>({ documentId: '', fileName: '', description: '' });

    const documents = useMemo(() => getPersonaVersion(chatInfo.persona, chatInfo.version, chatInfo.build).revision?.documents || globalDocuments, [getPersonaVersion, globalDocuments, chatInfo]);


    const fileUploadProps = useFileListState({ files: data.map(x => ({ ...x, id: x.documentId })), events });
    const renderSelectedNames = useMemo(() => fileUploadProps.renderSelectedNames({ ...fileUploadErrors }), [fileUploadProps]);

    useEffect(() => reportUploadStateChange?.(data), [data, reportUploadStateChange]);


    const persistentInput = useCallback(() => getInput(chatInfo.persona!, { documents: [] }), [getInput, chatInfo.persona]);
    const setDocumentIds = useCallback((documents: string[]) => mergeInput(chatInfo.persona!, { documents: [...(persistentInput().documents ?? []), ...documents] }), [mergeInput, persistentInput, chatInfo.persona]);
    const removeDocumentIds = useCallback((documents: string[]) => mergeInput(chatInfo.persona!, { documents: (persistentInput().documents ?? []).filter(doc => !documents.includes(doc)) }), [mergeInput, persistentInput, chatInfo.persona]);


    const loadDocuments = useCallback(async () => {
        removeError(errorId);
        setIsLoading(true);

        try {
            const documentIds = persistentInput().documents ?? [];
            if (!documentIds.length && !chatId)
                return;

            initialLoadRef.current = true;

            const response = await fetchDocuments({ fileIds: chatId ? [] : documentIds, sessionId: chatId });

            if (response.errors && Object.keys(response.errors).length > 0) {
                const errorMessages = Object.entries(response.errors).map(([_, errorMessage]) => `${errorMessage}`).join(", ");
                registerError({ [errorId]: { type: 'notification', headline: labels.fetchErrorHeadline, description: errorMessages } });
            }

            if (response.documents) {
                const rows = Object.values(response.documents).map((doc) => {
                    return ({
                        documentId: doc.id,
                        fileName: doc.display.name || "",
                        description: doc.display.description || "",
                        status: doc.state?.status || FileUploadStatus.Ready,
                        size: doc.size,
                        extension: doc.format,
                        created: doc.created
                    });
                });
                setData(rows);
            }
        }
        catch (error: any) {
            registerError({ [errorId]: { type: 'notification', headline: labels.fetchErrorHeadline, description: labels.fetchErrorMessage, details: error } });
        }
        finally {
            setIsLoading(false);
        }
    }, [removeError, errorId, persistentInput, chatId, fetchDocuments, registerError]);

    // Init documents load
    useEffect(() => { !initialLoadRef.current && loadDocuments(); }, [loadDocuments]);


    const handleFileUpload = useCallback(async (file: File, setUploadProgress: (progress: AxiosProgressEvent) => void) => {
        // Check if the file size is valid
        const { name: fileName } = getExtension(file?.name) || { extension: 'unknown', name: 'undefined' };

        const props: DocumentInfoModel = await uploadDocument({ file, sessionId: chatId, onUploadProgress: setUploadProgress });

        const { id, size, format, state } = props;
        if (!id) {
            console.error('Document ID is missing');
            return;
        }

        // Handle the case of new chat where the session ID is not yet available
        if (!chatId) setDocumentIds([id]);
        const uploadStatus = state?.status;
        if (!uploadStatus) {
            console.error('Upload status is missing');
            return;
        };

        setData(prev => [...prev, { ...props, fileName, description: '', documentId: id, size: size, extension: format, status: uploadStatus }]);
    }, [uploadDocument, chatId, setDocumentIds]);

    const handleDownload = useCallback(async () => {
        fileUploadProps.handleErrorRemoval();
        fileUploadProps.handleLoadingIdsChange();

        try {
            const success = await downloadDocuments({ fileIds: fileUploadProps.selectedIds });
            if (!success) fileUploadProps.handleErrorRegistering('labels.downloadErrorHeadline', 'labels.downloadErrorMessage');
            fileUploadProps.onDeselectAll();
        }
        catch (error) {
            fileUploadProps.handleErrorRegistering('labels.downloadErrorHeadline', 'labels.downloadErrorMessage', error);
        }
        finally {
            fileUploadProps.handleLoadingIdsChange([]);
        }
    }, [downloadDocuments, fileUploadProps]);

    const handleDelete = useCallback(async () => {
        fileUploadProps.handleErrorRemoval();
        fileUploadProps.handleLoadingIdsChange();

        try {
            const { delete_document_ids, errors } = await deleteDocuments({ fileIds: fileUploadProps.selectedIds, sessionId: chatId });
            const hasDeletedDocs = delete_document_ids?.length > 0;

            if (errors && Object.keys(errors).length) {
                const errorMessages = Object.values(errors).join(", ");
                fileUploadProps.handleErrorRegistering(fileUploadErrors.deleteError.headline, `${fileUploadErrors.deleteError.message} ${errorMessages}`, errors);
            }

            if (hasDeletedDocs) {
                setData(prev => {
                    const remainingDocuments = prev.filter(row => !delete_document_ids.includes(row.documentId));
                    return remainingDocuments;
                });
            }

            if (hasDeletedDocs && !chatId) {
                removeDocumentIds(delete_document_ids);
                if (!isString(chatInfo.persona)) return;
            }

            fileUploadProps.onDeselectAll();
            setActiveModal(undefined);
        }
        catch (error) {
            fileUploadProps.handleErrorRegistering('labels.deleteErrorHeadline', 'labels.deleteErrorMessage', error);
        }
        finally {
            fileUploadProps.handleLoadingIdsChange([]);
        }
    }, [chatId, chatInfo.persona, deleteDocuments, fileUploadProps, removeDocumentIds]);

    const handleEdit = useCallback(async () => {
        fileUploadProps.handleErrorRemoval();
        fileUploadProps.handleLoadingIdsChange();
        const metadata: DocumentUpdateRequest = { display: { name: editState.fileName, description: editState.description } };

        try {
            const response = await updateDocumentMetadata({ fileId: editState.documentId, metadata });
            if (!response) return;

            setData(prev => {
                const targetIndex = prev.findIndex((row => row.documentId === editState.documentId));
                if (targetIndex === -1) {
                    console.error('Document not found in the list');
                    return prev;
                };

                prev.splice(targetIndex, 1, { ...prev[targetIndex], ...metadata, ...metadata?.display, fileName: metadata?.display?.name || '-' });

                return [...prev];
            });
            fileUploadProps.onDeselectAll();
            setActiveModal(undefined);
        }
        catch (error) {
            fileUploadProps.handleErrorRegistering('labels.updateErrorHeadline', 'labels.updateErrorMessage', error);
        }
        finally {
            fileUploadProps.handleLoadingIdsChange([]);
        }
    }, [editState.description, editState.documentId, editState.fileName, fileUploadProps, updateDocumentMetadata]);

    const handleCopy = useCallback(async (selectedAssistant: string, hidePanelAfterCopy?: boolean) => {
        fileUploadProps.handleErrorRemoval();
        setIsLoading(true);

        const result: DocumentsResponse = await copyDocuments({ document_ids: fileUploadProps.selectedIds });

        try {
            if (result.errors && Object.keys(result.errors).length > 0) {
                const errorMessages = Object.values(result.errors).join(", ");
                fileUploadProps.handleErrorRegistering(fileUploadErrors.copyError.headline, errorMessages);
            }
            else {
                // Extract document IDs from result.documents
                const documents = Object.values(result.documents).map((doc) => doc.id);

                // Refresh documents list with new documents
                await loadDocuments(); // Prop of 'documents' used to be passed in?

                // Update document IDs in persistent state
                const assistantKey = selectedAssistant === advancedAssistantKey ? advancedAssistantRoute : selectedAssistant;
                setInput(assistantKey, { documents });

                const personaKey = getPersonaVersion(selectedAssistant).persona?.route;

                // Navigate to the chat route if not already there
                navigate({ pathname: '/' + personaKey, hash: hidePanelAfterCopy ? '' : hash });
            }
        }
        catch (error: any) {
            const errorMessage = error instanceof Error ? error.message : String(error);
            fileUploadProps.handleErrorRegistering('labels.copyErrorHeadline', errorMessage, error);
        }
        finally {
            setIsLoading(false);
        }
    }, [copyDocuments, fileUploadProps, getPersonaVersion, hash, loadDocuments, navigate, setInput]);


    const actions = useFileListActions({
        copy: {
            onClick: () => setActiveModal('copy'),
            isDisabled: !fileUploadProps.selectedIds.length,
            isHidden: !chatId,
        },
        remove: {
            onClick: () => setActiveModal('remove'),
            isDisabled: !fileUploadProps.selectedIds.length,
        },
        download: {
            onClick: handleDownload,
            isDisabled: !fileUploadProps.selectedIds.length,
        },
        edit: {
            onClick: () => {
                const file = fileUploadProps.files.find(file => fileUploadProps.selectedIds.includes(file.documentId));
                console.log({ file });

                if (!file) return console.error('File not found');
                setEditState({ documentId: file.documentId, fileName: file?.fileName, description: file?.description });
                setActiveModal('edit');
            },
            isDisabled: fileUploadProps.selectedIds.length !== 1,
        },
        refresh: {
            onClick: () => loadDocuments(),
        }
    });


    return (
        <div className={styles.wrapper} data-testid={TestIds.documentView}>
            <FileInput
                fileList={data}
                maxUploadNumber={documents?.max}
                maxFileUploadSize={documents?.length}
                allowedFileFormats={documents?.formats}
                onFileUpload={handleFileUpload}
            />

            <ErrorHandler.Notifications className={styles.error} id={errorId} />

            {isLoading
                ? <Loader />
                : <>
                    {isDemoTour ? true : !!data.length && <FileListActions
                        {...fileUploadProps}
                        actions={actions}
                    />}

                    <FileList
                        chipId={TourFileUploadTargets.statusIndicator}
                        {...fileUploadProps}
                        labels={fileUploadErrors}
                        files={isDemoTour ? demoTour : data}
                        onEdit={({ fileId: documentId, name: fileName, description }) => {
                            setEditState({ documentId, fileName, description });
                            setActiveModal('edit');
                        }}
                    />


                    <FileListActionsModal
                        isOpen={activeModal === 'remove'}
                        modal={{
                            headline: labels.deleteDocumentModalTitle
                        }}
                        close={{
                            label: labels.cancel,
                            onClick: () => setActiveModal(undefined)
                        }}
                        submit={{
                            label: labels.delete,
                            onClick: () => handleDelete()
                        }}
                        errorId={''}
                    >
                        <div>
                            {labels.deleteDocumentConfirmationText} {renderSelectedNames}
                        </div>
                    </FileListActionsModal>

                    <FileListActionsModal
                        isOpen={activeModal === 'edit'}
                        modal={{
                            headline: labels.documentUpdateHeader
                        }}
                        close={{
                            label: labels.cancel,
                            onClick: () => setActiveModal(undefined)
                        }}
                        submit={{
                            label: labels.update,
                            onClick: () => handleEdit()
                        }}
                        errorId={''}
                    >
                        <div>
                            <Input label={labels.namePlaceholder} value={editState.fileName} onChange={v => setEditState(s => ({ ...s, 'fileName': v }))} />
                            <br />
                            <Textarea label={labels.descriptionPlaceholder} rows={4} value={editState.description ?? ''} onChange={v => setEditState(s => ({ ...s, 'description': v }))} />
                        </div>
                    </FileListActionsModal>

                    <CopyModal
                        onCopy={handleCopy}
                        activeModal={activeModal}
                        setActiveModal={setActiveModal}
                        renderSelectedNames={renderSelectedNames}
                        selectedItems={fileUploadProps.selectedIds.length ? data.filter(file => fileUploadProps.selectedIds.includes(file.documentId)) : undefined}
                    />

                    {showInfo && <Information />}
                </>
            }
        </div>
    );
};


const CopyModal = ({ activeModal, selectedItems, renderSelectedNames, onCopy, setActiveModal, }: { selectedItems?: DocumentRow[], activeModal?: string, renderSelectedNames: ReactNode; onCopy: (selectedAssistant: string, minimizeSidePanel?: boolean) => void, setActiveModal: (state?: string) => void, }) => {
    const labels = useLabels();
    const { chatInfo } = useAdaptive();
    const { allowedPersonaOptions, getPersonaVersion } = useInfo();

    const [selectedAssistant, setSelectedAssistant] = useState<string | undefined>(chatInfo.persona);
    const { revision: { documents } = { documents: undefined } } = getPersonaVersion(selectedAssistant || '-') ?? {};
    const { formats, length: maxSize, max } = documents || {};

    const readableAssistantList = useCallback((list: { fileName: string; }[]) => list.map?.(({ fileName }) => fileName).join(', '), []);

    const inValidMessage = useMemo(() => {
        if (max && selectedItems && selectedItems?.length > max) return labels.copyErrorValidateMaxSize(max);

        const exceedsSize = maxSize ? selectedItems?.filter(({ size }) => size > maxSize) : [];
        if (!!exceedsSize?.length) return labels.copyErrorValidateMaxLimit(readableFileSize(maxSize), readableAssistantList(exceedsSize));

        const unsupportedFormats = formats && selectedItems?.filter(({ extension, }) => !formats.includes(extension));
        if (!!unsupportedFormats?.length) return labels.copyErrorValidateExtension(readableAssistantList(unsupportedFormats));
    }, [labels, formats, max, maxSize, readableAssistantList, selectedItems]);


    const allowedOptions = useMemo(() => allowedPersonaOptions
        .filter(a => a.versions
            .some(v => v.revisions
                .some(r => r.documents?.enabled)
            )
        ).map(({ title: label, key: value, versions }) => ({
            value,
            label,
            versions: versions.map(({ version, revisions }) => ({
                version,
                revisions: revisions.map(({ build, label }) => ({
                    build, label
                }))
            }))
        })), [allowedPersonaOptions]);

    const renderSelectAssistant = useMemo(() => {
        return <div className={styles['select-container']}>
            {allowedOptions.length > 1 && <SelectStatic
                label={labels.copySelectChatAssistant}
                value={selectedAssistant}
                options={allowedOptions}
                onChange={setSelectedAssistant}
            />}
        </div>;
    }, [allowedOptions, labels.copySelectChatAssistant, selectedAssistant, setSelectedAssistant]);

    const renderInValidMessage = useMemo(() => {
        if (!inValidMessage) return null;
        return <div className={styles['invalid-message']}>
            {inValidMessage}
        </div>;
    }, [inValidMessage]);

    const confirmCopy = useCallback(async () => {
        if (!isString(selectedAssistant)) return;
        const minimizeSidePanel = [tenderAssistantKey].includes(selectedAssistant);
        await onCopy(selectedAssistant, minimizeSidePanel);
        setActiveModal(undefined);
    }, [onCopy, selectedAssistant, setActiveModal]);

    return <FileListActionsModal
        isOpen={activeModal === 'copy'}
        modal={{
            headline: labels.copyToNewChatHeadline
        }}
        close={{
            label: labels.copyToNewChatCancelCta,
            onClick: () => setActiveModal(undefined)
        }}
        submit={{
            label: labels.copyToNewChatCta,
            onClick: confirmCopy,
            isDisabled: !!inValidMessage
        }}
        errorId={''}
    >
        <>
            {labels.copyToNewChatDescription}{renderSelectedNames}
            <br />
            <div className={styles.content}>
                {renderSelectAssistant}
                {renderInValidMessage}
            </div>
        </>
    </FileListActionsModal>;
};