export enum Permission {
    Manage = 'manage',
    Use = 'use',
    Test = 'test',
    Edit = 'edit',
    Delete = 'delete',
    Create = 'create',
    Publish = 'publish',
    Reference = 'reference',
}

/** Dictionary containing the permissions for the user.
 * - **Key**: is the scope. It can be an empty string (meaning current scope), a single scope or multiple scopes with "." as delimiter.
 * - **Value**: an array of strings each representing an action that will be permitted for the user
 */
export type PermissionsDict = Record<string, Permission[]>;

export type PermissionAction = (scope?: string) => boolean;

export type PermissionResponseType = {
    permissions: PermissionsDict;
};
