import { isString } from '../../../helpers/typeHelpers';
import { Permission, PermissionAction, PermissionsDict } from '../models/Permissions';

const normalizeScope = (scope: string): string => scope.trim().toLowerCase();
const checkPermission = (permissions: PermissionsDict, scope: string | undefined, action: Permission): boolean => {
    if (!scope) return false;

    const normalizedScope = normalizeScope(scope);
    const hasPermission = permissions[normalizedScope]?.includes(action);
    return !!hasPermission;
};

export const canPerformActionFactory = (permissions: PermissionsDict, action: Permission): PermissionAction => {
    return (scope?: string) => {
        const hasPermission = checkPermission(permissions, scope, action);
        return hasPermission;
    };
};

export const hasPermission = ({ key, defaultScope, permission, permissions, processor }: { key?: string, defaultScope?: string, permissions: PermissionsDict; permission: Permission; processor: (id?: string) => string; }) => {
    const scope = key ? processor(key) : defaultScope;
    return canPerformActionFactory(permissions, permission)(scope);
};

export const resolveScope = (...scopes: (undefined | string)[]): string => {
    return scopes.filter(isString).map(normalizeScope).join('.');
};