import { generatePath, PathParam } from "react-router-dom";

export type RoutingPathsType = {
    admin: {};
    assistants: {};
    assistant: { assistantId: string; };
    share: { assistantId: string; };
    evaluate: { assistantId: string; };
    settings: { assistantId: string; };
    versions: { assistantId: string; versionNumber: string; };
    prompt: { assistantId: string; versionNumber: string; };
    changelog: { assistantId: string; versionNumber: string; };
    knowledge: { assistantId: string; versionNumber: string; };
    publish: { assistantId: string; versionNumber: string; };
    knowledgeTool: {
        assistantId: string;
        versionNumber: string;
        toolId: string;
    };

    models: {};
    model: { modelId: string; };

    tools: {};
    tool: { toolId: string; };
};



export enum AdminRoutes {
    admin = "admin",
    assistants = "assistants",
    assistant = ":assistantId",
    share = "share",
    evaluate = "evaluate",
    settings = "settings",

    versions = ":assistantId/settings/:versionNumber",
    prompt = "prompt",
    changelog = "changelog",
    knowledge = "knowledge",
    publish = "publish",
    knowledgeTool = ":toolId",

    models = "models",
    model = ":modelId",

    tools = "tools",
    tool = ":toolId",

    // quotas = "quotas",
}

export type RoutingPathsKeyListType = keyof typeof fullAdminPaths;

export const adminBasePath = "/admin";
const assistantsBasePath = `${adminBasePath}/assistants`;
const assistantBasePath = `${assistantsBasePath}/:assistantId`;

const modelsBasePath = `${adminBasePath}/models`;

const toolsBasePath = `${adminBasePath}/tools`;

const fullAdminPaths = {
    admin: adminBasePath,
    assistants: assistantsBasePath,
    assistant: assistantBasePath,
    share: `${assistantBasePath}/share`,
    evaluate: `${assistantBasePath}/evaluate`,
    settings: `${assistantBasePath}/settings`,
    versions: `${assistantBasePath}/settings/:versionNumber`,
    prompt: `${assistantBasePath}/settings/:versionNumber/prompt`,
    changelog: `${assistantBasePath}/settings/:versionNumber/changelog`,
    knowledge: `${assistantBasePath}/settings/:versionNumber/knowledge`,
    publish: `${assistantBasePath}/settings/:versionNumber/publish`,
    knowledgeTool: `${assistantBasePath}/settings/:versionNumber/knowledge/:toolId`,


    models: modelsBasePath,
    model: `${modelsBasePath}/${AdminRoutes.model}`,


    tools: toolsBasePath,
    tool: `${toolsBasePath}/${AdminRoutes.tool}`,
};

export const generateAdminPath = <K extends RoutingPathsKeyListType>(type: K, params: RoutingPathsType[K]): string => {
    return generatePath(fullAdminPaths[type], params as PathParam<string>);
};

export const invokeAdminPathWithInference = (callback: (pathname: string) => void, inferredParams: any) => <K extends RoutingPathsKeyListType>(type: K) => {
    const pathname = generateAdminPath(type, inferredParams);
    callback(pathname);
};

export const invokeAdminPath = (callback: (pathname: string) => void) => <K extends RoutingPathsKeyListType>(type: K, params: RoutingPathsType[K]) => {
    const pathname = generateAdminPath(type, params);
    callback(pathname);
};

export const getAdminParamsOnPath = (params: unknown) => <K extends RoutingPathsKeyListType>(type: K): RoutingPathsType[K] => {
    return params as RoutingPathsType[K];
};