// import { lazy } from 'react';
import { IconComponent, IconStyles } from "./Icon.types";
import { Icons } from "./icons/material";

import Adaptive from "./variation/Adaptive";
import Async from "./variation/Async";
import Base from "./variation/Base";
import Svg from "./variation/Svg";
import Selector from "./variation/Selector";
import Preview from './variation/Preview';

// TODO:
// const Adaptive = lazy(() => import("./variation/Adaptive"));
// const Async = lazy(() => import("./variation/Async"));
// const Base = lazy(() => import("./variation/Base"));
// const Svg = lazy(() => import("./variation/Svg"));
// const Selector = lazy(() => import("./variation/Selector"));
// const Preview = lazy(() => import('./variation/Preview'));

const Icon: IconComponent = () => {};
Icon.Adaptive = Adaptive;
Icon.Base = Base;
Icon.Async = Async;
Icon.Svg = Svg;
Icon.Selector = Selector;
Icon.Preview = Preview;

export { Icon };
// Relevant for additions via  plain JS
export const getInlineIcon = ({ iconName, style = IconStyles.outlined }: { iconName: Icons; style?: IconStyles; }) => !iconName ? '' : `<span class="${style}">${iconName}</span>`;