import { useContext } from 'react';
import AdminPermissionsContext from '../contexts/AdminPermissionsContext';
import { AdminPermissionsContextValue } from '../types/Context';

const useAdminPermissions = (): AdminPermissionsContextValue => {
    const context = useContext(AdminPermissionsContext);

    if (!context) {
        // Shoud we?
        throw new Error('useAdminPermissions must be used within an AdminPermissionsProvider');
    }

    return context;
};

export default useAdminPermissions;