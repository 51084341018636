import EnvConfigHelper from '../helpers/envConfigHelper';
import { HTTPDataService } from './HTTPDataService';
import type { InfoResponse, LegacyPersonaProperties, PersonaOption, TermsResponse } from '../models/types';
import type { IAppInfoService } from './IAppInfoService';
import { defaultCulture } from '../constants/consts';
import ServiceBackendEventsHelper from './helpers/ServiceBackendEventsHelper';
import { IBackendEventsService } from './IBackendEventsService';
import EventHandler from './EventHandler';
import { PreferencesEvent } from '../models/BackendEvents';
import { trimChar } from '../helpers/stringHelpers';


class AppInfoService extends HTTPDataService implements IAppInfoService {
    baseURL = EnvConfigHelper.get('api-base-url', '');
    backendEventsHelper: ServiceBackendEventsHelper;
    constructor(public getAccessToken: () => Promise<string | undefined>, backendEvents: IBackendEventsService) {
        super();
        this.backendEventsHelper = new ServiceBackendEventsHelper(this.events, backendEvents);
    }
    private populateLegacyProperties = (info: InfoResponse) => {
        const action = (p: PersonaOption) => {
            const persona = p as PersonaOption & LegacyPersonaProperties;
            // If persona is not assigned, this indicates it's coming from the new API and there is no need to migrate
            if (!persona.version)
                return;

            // Below we handle legacy API response.
            persona.route = persona.route || persona.key;

            persona.versions = [
                {
                    version: persona.version,
                    date: persona.versionsHistory?.find(v => v.version === persona.version)?.releaseDate ?? "",
                    highlights: (persona.versionHighlights ?? "").split('\n').filter(x => !!x).map(x => trimChar(x, [" ", "-"])),
                    revisions: [
                        {
                            version: persona.version,
                            build: 1,
                            label: `${persona.version}${(!persona.beta ? '' : ' (1) - Beta')}`,
                            alpha: false,
                            beta: !!persona.beta,
                            characterLimit: persona.characterLimit,
                            documents: persona.documents
                        }
                    ]
                }
            ];
        };

        info.personas?.forEach(action);
    };

    private addBaseUrlToPersonaIcons = (info: InfoResponse) => {
        const prefixes = ['/assets/image/', '/static/'];
        const properties: (keyof Pick<PersonaOption, 'avatar' | 'icon'>)[] = ["icon", "avatar"];
        const action = (p: PersonaOption) => {
            properties.forEach(property => {
                if (p[property] && prefixes.some(prefix => p[property]?.startsWith(prefix))) {
                    p[property] = `${this.baseURL}${p[property]}`;
                }
            });
        };

        info.personas?.forEach(action);
    };
    getInfo = async () => {
        return new Promise<InfoResponse>(async (resolve, reject) => {
            try {
                const result = await this.get<InfoResponse>('api/info');
                this.populateLegacyProperties(result);
                this.addBaseUrlToPersonaIcons(result);

                resolve(result);
            } catch (e) {
                console.error(e);
                reject(e);
            }
        });
    };

    getTerms = async () => {
        try {
            const uri = 'api/info/terms';
            const query = {
                culture: defaultCulture
            };
            const termsPromise = this.get<TermsResponse>(uri, query);
            return await termsPromise;
        } catch (e) {
            console.error(e);
            return Promise.reject(e);
        }
    };

    getPersonaVersionReleaseNotes = async (persona: string, version: string) => {
        const uri = 'api/info/personas-version-release-notes';
        const query = {
            persona, version
        };
        return this.get<string>(uri, query);
    };

    events = {
        preferences: new EventHandler<PreferencesEvent>()
    };

    bindEvents = () => {
        this.backendEventsHelper.bind();
    };
    unbindEvents = () => {
        this.backendEventsHelper.unbind();
    };
}

export default AppInfoService;