import { createContext } from 'react';
import { AdminPermissionsContextValue } from '../types/Context';

const defaultAdminPermissions: AdminPermissionsContextValue = {
    canView: (_scope) => false,
    showAdminLink: () => false,
};

const AdminPermissionsContext = createContext(defaultAdminPermissions);

export default AdminPermissionsContext;